import { AppDispatch } from '../../Hooks/useTypedDispatch';
import { ReduxState } from '../ReduxInterface';
import {
  AddBlocksRequest,
  AddEntityDTO,
  CreateEntitiesRequest,
  MigrateCharacterInShowAndEpisodeToNewCharacterRequest,
  StoryCrafterBlockDetails,
  StoryCrafterEntityDetails,
  StoryCrafterEntityType,
  StoryCrafterPanelCharacter,
  StoryCrafterPanelDetails,
} from '../../Models/StoryMode/StoryCrafter';
import { AppStateActions } from './AppState';
import {
  addBlocks,
  createEntities,
  migrateCharacterInShowAndEpisodeToNewCharacter,
} from '../../Requests/Studio/StoryCrafter';
import { fetchAllVoices } from '../../Requests/Studio/Voices';
import { Voices } from '../../Models/Voices';
import { StoryCrafterBlock } from '../../Models/StoryMode/StoryCrafterBlocks';

export enum StoryCrafterStateActionV2 {
  SET_STORY_CHUNKS = 'SET_STORY_CHUNKS',
  SET_EPISODE_CHUNKS = 'SET_EPISODE_CHUNKS',
  SET_ENTITIES = 'SET_ENTITIES',
  SET_BLOCKS = 'SET_BLOCKS',
  SET_NEW_BLOCKS = 'SET_NEW_BLOCKS',
  ADD_TO_UPDATED_BLOCKS = 'ADD_TO_UPDATED_BLOCKS',
  REMOVE_FROM_UPDATED_BLOCKS = 'REMOVE_FROM_UPDATED_BLOCKS',
  ADD_TO_UPDATED_DIALOGS = 'ADD_TO_UPDATED_DIALOGS',
  SET_ASSOCIATIONS = 'SET_ASSOCIATIONS',
  SET_SHAPE_ASSOCIATION_MAP = 'SET_SHAPE_ASSOCIATION_MAP',
  SET_CHARACTER_EXTRACTION_LOADING = 'SET_CHARACTER_EXTRACTION_LOADING',
  SET_SCREENPLAY_LOADING = 'SET_SCREENPLAY_LOADING',
  SET_CHARACTER_MODAL_OPEN = 'SET_CHARACTER_MODAL_OPEN',
  SET_ADD_CHARACTER_MODAL_FOR_VALIDATION_OPEN = 'SET_ADD_CHARACTER_MODAL_FOR_VALIDATION_OPEN',
  SET_ADD_PANELS_FROM_SCRATCH_LOADING = 'SET_ADD_PANELS_FROM_SCRATCH_LOADING',
  SET_ADD_PANELS_LOADING = 'SET_ADD_PANELS_LOADING',
  SET_DELETING_ENTITY = 'SET_DELETING_ENTITY',
  SET_EXPAND_ALL = 'SET_EXPAND_ALL',
  RESET_STORYCRAFTER_STATE = 'RESET_STORYCRAFTER_STATE',
  SET_STORY_TYPE = 'SET_STORY_TYPE',
  SET_STORYCRAFTER_STORY = 'SET_STORYCRAFTER_STORY',
  SET_STORYCRAFTER_SELECTED_OPTION = 'SET_STORYCRAFTER_SELECTED_OPTION',
  SET_STORYCRAFTER_STORY_LOADING = 'SET_STORYCRAFTER_STORY_LOADING',
  SET_STORYCRAFTER_STORY_ERROR = 'SET_STORYCRAFTER_STORY_ERROR',
  SET_STORYCRAFTER_LAST_BLOCK_SEEN = 'SET_STORYCRAFTER_LAST_BLOCK_SEEN',
  SET_STORYCRAFTER_LAST_IMAGE_GENERATED = 'SET_STORYCRAFTER_LAST_IMAGE_GENERATED',
  SET_FETCHING_CHUNKS = 'SET_FETCHING_CHUNKS',
  SET_BULK_EDITING_STATE = 'SET_BULK_EDITING_STATE',
  SET_COPIED_BLOCK = 'SET_COPIED_BLOCK',
  UPDATE_STORY_CRAFTER_ENTITIES = 'UPDATE_STORY_CRAFTER_ENTITIES',
  SET_SHOW_VOICES_LOADING = 'SET_SHOW_VOICES_LOADING',
  SET_SHOW_VOICES_ERROR = 'SET_SHOW_VOICES_ERROR',
  SET_SHOW_VOICES_SUCCESS = 'SET_SHOW_VOICES_SUCCESS',
  SET_PANEL_GENERATION_SUMMARY = 'SET_PANEL_GENERATION_SUMMARY',
  UPDATE_GENERATION_COMPUTED_SUMMARY = 'UPDATE_GENERATION_COMPUTED_SUMMARY',
  SET_DOWNLOAD_ASSETS_MODAL_OPEN = 'SET_DOWNLOAD_ASSETS_MODAL_OPEN',
}

export const checkForMissingCharacter =
  ({ blockIds }: { blockIds: string[] } = { blockIds: [] }) =>
  (dispatch: AppDispatch, getState: () => ReduxState) => {
    const state = getState();
    const { showCharacters } = state.character;
    const { blocks } = state.storyCrafterStateV2;

    const showCharacterIds = new Set(showCharacters.map(char => char.character_id));
    const missingCharacters = new Set<StoryCrafterPanelCharacter>();
    // if block id is not empty, check against block id, else check all blocks. if length is 0, check all blocks
    const filteredBlocks =
      blockIds.length > 0 ? blocks.filter(block => blockIds.includes(block.entityId)) : blocks;
    const hasMissingCharacters = filteredBlocks.some(block =>
      block.panels.some(panel => {
        const details = panel.details as StoryCrafterPanelDetails;
        const missing = (details.characters || []).filter(
          char => !showCharacterIds.has(char.dashCharacterId ?? '')
        );
        missing.forEach(char => missingCharacters.add(char));
        return missing.length > 0; // Stop iteration if missing characters are found
      })
    );

    if (hasMissingCharacters) {
      dispatch({
        type: AppStateActions.OPEN_CHARACTER_STORY_VALIDATION_MODAL,
        payload: true,
      });
      return true;
    }
    return false;
  };

export const migrateCharacterInShowAndEpisodeToNewCharacterThunk =
  (request: MigrateCharacterInShowAndEpisodeToNewCharacterRequest, resourceId: string) =>
  async (dispatch: AppDispatch) => {
    // make api request, on success update entities?
    try {
      // do any loading actions
      const response = await migrateCharacterInShowAndEpisodeToNewCharacter(request, resourceId);
      if (response && response.length > 0) {
        dispatch({
          type: StoryCrafterStateActionV2.UPDATE_STORY_CRAFTER_ENTITIES,
          payload: response,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

export interface StoryCrafterStateV2ActionRequest {
  type: StoryCrafterStateActionV2;
  payload: any;
}

export const getVoicesThunk =
  () =>
  async (dispatch: AppDispatch, getState: () => ReduxState): Promise<Voices[]> => {
    try {
      if (getState().storyCrafterStateV2.voicesData.loading) {
        return [];
      }
      dispatch({
        type: StoryCrafterStateActionV2.SET_SHOW_VOICES_LOADING,
        payload: true,
      });
      const response = await fetchAllVoices();
      dispatch({
        type: StoryCrafterStateActionV2.SET_SHOW_VOICES_SUCCESS,
        payload: response,
      });
      return response;
    } catch (e) {
      dispatch({
        type: StoryCrafterStateActionV2.SET_SHOW_VOICES_ERROR,
        payload: e,
      });
      return [];
    }
  };

const createEmptyPanelEntity = (): AddEntityDTO => {
  return {
    details: {
      description: '',
      location: '',
      timeOfDay: '',
      cameraAngle: '',
      cameraShot: '',
      characters: [],
    },
    type: StoryCrafterEntityType.PANEL,
  };
};

const findAdjacentBlocksForInsert = (
  blocks: StoryCrafterBlock[],
  currentEntityId: string | null,
  addAfter: boolean
) => {
  if (!currentEntityId) {
    return {
      prevBlockId: null,
      nextBlockId: blocks[0]?.entityId ?? null,
      newIndex: 0,
    };
  }
  const currentIndex = blocks.findIndex(block => block.entityId === currentEntityId);
  if (currentIndex === -1) {
    return {
      prevBlockId: null,
      nextBlockId: null,
    };
  }
  const prevBlockId = addAfter
    ? blocks[currentIndex]?.entityId
    : blocks[currentIndex - 1]?.entityId ?? null;
  const nextBlockId = addAfter
    ? blocks[currentIndex + 1]?.entityId ?? null
    : blocks[currentIndex]?.entityId;
  const newIndex = addAfter ? currentIndex + 1 : currentIndex;
  return {
    prevBlockId,
    nextBlockId,
    newIndex,
  };
};

const createEmptyBlockEntity = ({
  panelIds,
  dialogIds,
}: {
  panelIds: string[];
  dialogIds: string[];
}): AddEntityDTO => {
  return {
    details: {
      panels: panelIds,
      dialogs: dialogIds,
    },
    type: StoryCrafterEntityType.BLOCK,
  };
};

export const addEmptyBlockThunk =
  ({
    showId,
    episodeId,
    currentEntityId,
    addAfter = true,
  }: {
    showId: string;
    episodeId: string;
    currentEntityId: string | null;
    addAfter: boolean;
  }) =>
  async (
    dispatch: AppDispatch,
    getState: () => ReduxState
  ): Promise<{
    newBlocks: StoryCrafterBlock[];
    prevBlockId: string | null;
    nextBlockId: string | null;
    newIndex: number;
  }> => {
    try {
      const request: CreateEntitiesRequest = {
        entities: [createEmptyPanelEntity()],
        insert: true,
        showId: showId,
        episodeId: episodeId,
      };
      const { entities: panels } = await createEntities(request);

      const blockRequest: CreateEntitiesRequest = {
        entities: [
          createEmptyBlockEntity({
            panelIds: panels.map(it => it.entityId),
            dialogIds: [],
          }),
        ],
        insert: true,
        showId: showId,
        episodeId: episodeId,
      };
      const { entities: blockEntities } = await createEntities(blockRequest);
      const newBlocks = blockEntities.map(it => {
        const details = it.details as StoryCrafterBlockDetails;
        return {
          ...details,
          panels: panels.filter(panel => details.panels.includes(panel.entityId)),
          entityId: it.entityId,
          episodeId: it.episodeId,
          dialogs: [],
        };
      });

      const blocks = getState().storyCrafterStateV2.blocks;
      const { prevBlockId, nextBlockId, newIndex } = findAdjacentBlocksForInsert(
        blocks,
        currentEntityId,
        addAfter
      );
      const addBlocksRequest: AddBlocksRequest = {
        blocks: newBlocks,
        showId: showId,
        nextBlockId: nextBlockId,
        prevBlockId: prevBlockId,
      };
      const addBlocksResponse = await addBlocks(addBlocksRequest);
      const updatedBlocks = [...blocks.slice(0, newIndex), ...newBlocks, ...blocks.slice(newIndex)];
      dispatch({
        type: StoryCrafterStateActionV2.SET_BLOCKS,
        payload: {
          blocks: updatedBlocks,
        },
      });
      dispatch({
        type: StoryCrafterStateActionV2.SET_NEW_BLOCKS,
        payload: {
          newBlocks: blockEntities.map(it => it.entityId),
        },
      });
      return { newBlocks, nextBlockId, prevBlockId, newIndex: newIndex ?? -1 };
    } catch (e) {
      console.error(e);
      return {
        newBlocks: [],
        nextBlockId: null,
        prevBlockId: null,
        newIndex: -1,
      };
    }
  };

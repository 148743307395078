export interface SavedVideoDTO {
  id: string;
  createdAt: string;
  videoUrl: string;
  duration: string;
  width: number;
  height: number;
  aspectRatio: string;
  prompt: string;
  generationId: string;
  batchCount: number;
  savedBy: string;
  showId: string;
  episodeId: string;
  state: SavedMediaState;
  metadata: SavedVideoMetadata[];
  updatedAt: string;
  resultType: VideoResultType;
  userId: string;
  creditsUsed: number;
  isFavourite: boolean;
}

export enum SavedVideoMetadataTypes {
  THUMBNAIL_URL = 'THUMBNAIL_URL',
  MODEL = 'MODEL',
}

export interface SavedVideoMetadata {
  name: SavedVideoMetadataTypes;
  value: string;
}

export enum SavedMediaState {
  FAILED = 'FAILED',
  SAVED = 'SAVED',
  PROCESSING = 'PROCESSING',
  DELETED = 'DELETED',
  PENDING = 'PENDING',
}

export enum VideoResultType {
  IMG2VID_RESULT_KLING_1_5_PRO = 'IMG2VID_RESULT_KLING_1_5_PRO',
  IMG2VID_RESULT_KLING_1_6_PRO = 'IMG2VID_RESULT_KLING_1_6_PRO',
  IMG2VID_RESULT_I2V_01_LIVE_MINIMAX = 'IMG2VID_RESULT_I2V_01_LIVE_MINIMAX',
  UPLOADED = 'UPLOADED',
}

export interface SavedVideoState {
  savedVideos: SavedVideoDTO[];
}

export const initialSavedVideoState: SavedVideoState = {
  savedVideos: [],
};

export interface UploadVideoRequest {
  videoUrl: string;
  videoThumbnailUrl: string;
  showId: string;
  episodeId: string;
}

export interface MarkSavedVideoFavouriteRequest {
  savedVideoId: string;
  isFavourite: boolean;
  showId: string;
  episodeId: string;
}

import { SavedAudioDTO, UploadAudioRequest } from '../../Models/SavedAudio';
import { AppDispatch } from '../../Hooks/useTypedDispatch';
import {
  getSavedAudioByShowIdAndEpisodeId,
  uploadAudioToEpisode,
} from '../../Requests/Studio/SavedAudio';

export enum AudioGenerationActions {
  ADD_PENDING_AUDIO_GENERATION = 'ADD_PENDING_AUDIO_GENERATION',
  REMOVE_PENDING_AUDIO_GENERATION = 'REMOVE_PENDING_AUDIO_GENERATION',
  ADD_MULTIPLE_SAVED_AUDIO_GENERATION = 'ADD_MULTIPLE_SAVED_AUDIO_GENERATION',
}

export interface AudioGenerationAction {
  type: AudioGenerationActions;
  payload: string[];
}
export const addPendingAudioGeneration = (generationIds: string[]): AudioGenerationAction => ({
  type: AudioGenerationActions.ADD_PENDING_AUDIO_GENERATION,
  payload: generationIds,
});

export const removePendingAudioGeneration = (generationIds: string[]): AudioGenerationAction => ({
  type: AudioGenerationActions.REMOVE_PENDING_AUDIO_GENERATION,
  payload: generationIds,
});

export interface AddMultipleSavedAudioGenerationAction {
  type: AudioGenerationActions.ADD_MULTIPLE_SAVED_AUDIO_GENERATION;
  payload: SavedAudioDTO[];
}

export const addMultipleSavedAudioGeneration = (
  payload: SavedAudioDTO[]
): AddMultipleSavedAudioGenerationAction => ({
  type: AudioGenerationActions.ADD_MULTIPLE_SAVED_AUDIO_GENERATION,
  payload,
});

export const fetchSavedAudioByShowIdAndEpisodeIdThunk =
  ({ showId, episodeId }: { showId: string; episodeId: string }) =>
  async (dispatch: AppDispatch) => {
    try {
      const response = await getSavedAudioByShowIdAndEpisodeId({ showId, episodeId });
      dispatch(addMultipleSavedAudioGeneration(response));
    } catch (error) {
      console.error(`Unable to fetch saved audio by show id and episode id: ${error}`);
      throw error;
    }
  };

export const uploadAudioToEpisodeThunk =
  (request: UploadAudioRequest) => async (dispatch: AppDispatch) => {
    try {
      const response = await uploadAudioToEpisode(request);
      dispatch(addMultipleSavedAudioGeneration([response]));
    } catch (error) {
      console.error(`Unable to upload audio to episode: ${error}`);
      throw error;
    }
  };

export type AudioGenerationActionTypes =
  | AudioGenerationAction
  | AddMultipleSavedAudioGenerationAction;

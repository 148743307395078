import { VideoGenerationAction } from '../../Reducers/VideoGeneration/VideoGeneration';
import { UserGenerationsData } from '../../../Models/PollRequests';
import { BulkInterfacePromptRequest } from '../../../Models/VideoGeneration/VideoGeneration';
import {
  LipSyncVideoInputSource,
  VideoGenMode,
  VideoGenModes,
} from '../../../Components/CanvasV3/VideoMode/RightBar/VideoGenerationMenu/VideoGenMode';
import { AppDispatch } from '../../../Hooks/useTypedDispatch';
import { GenModeEnum } from '../../../Models/GenMode';
import { enhancePrompt } from '../../../Requests/Studio/TextEnhancer';
import { PromptEnhanceType } from '../../../Models/TextEnhancer';
import { ReduxState } from '../../ReduxInterface';

export enum VideoGenerationActions {
  SET_MODEL = 'SET_MODEL',
  SET_PROMPT = 'SET_PROMPT',
  SET_NEGATIVE_PROMPT = 'SET_NEGATIVE_PROMPT',
  SET_DURATION = 'SET_DURATION',
  SET_ASPECT_RATIO = 'SET_ASPECT_RATIO',
  SET_EXPORT_FPS = 'SET_EXPORT_FPS',
  SET_GUIDANCE_SCALE = 'SET_GUIDANCE_SCALE',
  SET_NUM_STEPS = 'SET_NUM_STEPS',
  SET_NUM_FRAMES = 'SET_NUM_FRAMES',
  ADD_INIT_IMAGE = 'ADD_INIT_IMAGE',
  REMOVE_INIT_IMAGE = 'REMOVE_INIT_IMAGE',
  SET_BATCH_COUNT = 'SET_BATCH_COUNT',
  ADD_METADATA = 'ADD_METADATA',
  REMOVE_METADATA = 'REMOVE_METADATA',
  RESET_STATE = 'RESET_STATE',
  ADD_PENDING_VIDEO_GENERATION = 'ADD_PENDING_VIDEO_GENERATION',
  REMOVE_PENDING_VIDEO_GENERATION = 'REMOVE_PENDING_VIDEO_GENERATION',
  SET_SELECTED_VIDEO = 'SET_SELECTED_VIDEO',
  SET_PENDING_VIDEO_GENERATION = 'SET_PENDING_VIDEO_GENERATION',
  ADD_PENDING_GENERATION_STATE = 'ADD_PENDING_GENERATION_STATE',
  SET_PENDING_GENERATION_STATE = 'SET_PENDING_GENERATION_STATE',
  SET_BULK_VIDEO_GENERATE_MODE = 'SET_BULK_VIDEO_GENERATE_MODE',
  ADD_BULK_VIDEO_GENERATE_BLOCK = 'ADD_BULK_VIDEO_GENERATE_BLOCK',
  REMOVE_BULK_VIDEO_GENERATE_BLOCK = 'REMOVE_BULK_VIDEO_GENERATE_BLOCK',
  UPDATE_BULK_GENERATE_MODE = 'UPDATE_BULK_GENERATE_MODE',
  SET_VIDEO_GEN_MODE = 'SET_VIDEO_GEN_MODE',
  SET_LIPSYNC_INPUT_SOURCE = 'SET_LIPSYNC_INPUT_SOURCE',
  ADD_BULK_LIPSYNC_VIDEO_BLOCK = 'ADD_BULK_LIPSYNC_VIDEO_BLOCK',
  REMOVE_BULK_LIPSYNC_VIDEO_BLOCK = 'REMOVE_BULK_LIPSYNC_VIDEO_BLOCK',
  ADD_NEW_AUDIO_CLIP = 'ADD_NEW_AUDIO_CLIP',
  SET_LIPSYNC_AUDIO_CLIP = 'SET_LIPSYNC_AUDIO_CLIP',
  REMOVE_AUDIO_CLIP = 'REMOVE_AUDIO_CLIP',
  SET_LIPSYNC_VIDEO_CLIP = 'SET_LIPSYNC_VIDEO_CLIP',
  SET_VIDEO_FAVOURITE_STATE = 'SET_VIDEO_FAVOURITE_STATE',
  ADD_NEW_VIDEO_LIPSYNC_BLOCK = 'ADD_NEW_VIDEO_LIPSYNC_BLOCK',
  SET_LOOP = 'SET_LOOP',
}

export const addPendingVideoGeneration = (generationIds: string[]): VideoGenerationAction => ({
  type: VideoGenerationActions.ADD_PENDING_VIDEO_GENERATION,
  payload: generationIds,
});

export const removePendingVideoGeneration = (generationIds: string[]): VideoGenerationAction => ({
  type: VideoGenerationActions.REMOVE_PENDING_VIDEO_GENERATION,
  payload: generationIds,
});

export const addPendingGenerationState = (
  generationState: UserGenerationsData
): VideoGenerationAction => ({
  type: VideoGenerationActions.ADD_PENDING_GENERATION_STATE,
  payload: generationState,
});

export const setPendingGenerationState = (
  generationState: UserGenerationsData[]
): VideoGenerationAction => ({
  type: VideoGenerationActions.SET_PENDING_GENERATION_STATE,
  payload: generationState,
});

export const setBulkVideoGenerateMode = (enabled: boolean): VideoGenerationAction => ({
  type: VideoGenerationActions.SET_BULK_VIDEO_GENERATE_MODE,
  payload: enabled,
});

export const addBulkVideoGenerateBlock = (): VideoGenerationAction => ({
  type: VideoGenerationActions.ADD_BULK_VIDEO_GENERATE_BLOCK,
});

export const removeBulkVideoGenerateBlock = (index: number): VideoGenerationAction => ({
  type: VideoGenerationActions.REMOVE_BULK_VIDEO_GENERATE_BLOCK,
  payload: index,
});

export const updateBulkGenerateMode = (
  index: number,
  data: BulkInterfacePromptRequest
): VideoGenerationAction => ({
  type: VideoGenerationActions.UPDATE_BULK_GENERATE_MODE,
  payload: { index, data },
});

export const setVideoGenMode = (mode: VideoGenMode): VideoGenerationAction => ({
  type: VideoGenerationActions.SET_VIDEO_GEN_MODE,
  payload: mode,
});

export const setInputSource = (source: LipSyncVideoInputSource): VideoGenerationAction => ({
  type: VideoGenerationActions.SET_LIPSYNC_INPUT_SOURCE,
  payload: source,
});

export const addBulkLipSyncVideoBlock = (): VideoGenerationAction => ({
  type: VideoGenerationActions.ADD_BULK_LIPSYNC_VIDEO_BLOCK,
});

export const removeBulkLipSyncVideoBlock = (index: number): VideoGenerationAction => ({
  type: VideoGenerationActions.REMOVE_BULK_LIPSYNC_VIDEO_BLOCK,
  payload: index,
});

export const addNewAudioClip = (blockIndex: number) => ({
  type: VideoGenerationActions.ADD_NEW_AUDIO_CLIP,
  payload: blockIndex,
});

export const setLipSyncAudioClip = ({
  audioUrl,
  blockIndex,
  audioIndex,
}: {
  audioUrl: string;
  blockIndex: number;
  audioIndex: number;
}) => ({
  type: VideoGenerationActions.SET_LIPSYNC_AUDIO_CLIP,
  payload: { audioUrl, blockIndex, audioIndex },
});

export const removeAudioClip = (blockIndex: number, audioIndex: number) => ({
  type: VideoGenerationActions.REMOVE_AUDIO_CLIP,
  payload: { blockIndex, audioIndex },
});

export const addNewVideoLipSyncBlock = ({
  videoUrl,
  videoThumbnailUrl,
  generationId,
}: {
  videoUrl: string;
  videoThumbnailUrl: string;
  generationId?: string;
}) => ({
  type: VideoGenerationActions.ADD_NEW_VIDEO_LIPSYNC_BLOCK,
  payload: { videoUrl, videoThumbnailUrl, generationId },
});

export const setLipSyncVideoClip = ({
  videoUrl,
  blockIndex,
  videoThumbnailUrl,
  generationId,
}: {
  videoUrl: string;
  blockIndex: number;
  videoThumbnailUrl: string;
  generationId?: string;
}) => ({
  type: VideoGenerationActions.SET_LIPSYNC_VIDEO_CLIP,
  payload: { videoUrl, blockIndex, videoThumbnailUrl, generationId },
});

export const sendVideoToLipSyncModeThunk =
  ({
    videoUrl,
    videoThumbnailUrl,
    videoGenerationId,
  }: {
    videoUrl: string;
    videoThumbnailUrl: string;
    videoGenerationId: string;
  }) =>
  (dispatch: AppDispatch) => {
    const mode = VideoGenModes.find(g => g.id === GenModeEnum.LIPSYNC);
    if (!mode) return;
    dispatch(setVideoGenMode(mode));
    dispatch(
      addNewVideoLipSyncBlock({ videoUrl, videoThumbnailUrl, generationId: videoGenerationId })
    );
  };

export const enhanceVideoPromptThunk =
  ({ prompt, blockIdx, promptIdx }: { prompt: string; blockIdx: number; promptIdx: number }) =>
  async (dispatch: AppDispatch, getState: () => ReduxState) => {
    try {
      const response = await enhancePrompt({ text: prompt, type: PromptEnhanceType.VIDEO_PROMPT });
      const currentBlock = getState().videoGenerationState.bulkGenerateMode.promptData.find(
        (b, idx) => idx === blockIdx
      );
      if (!currentBlock) {
        console.error('Block not found to update prompt');
        return;
      }
      dispatch(
        updateBulkGenerateMode(blockIdx, {
          ...currentBlock,
          prompt: currentBlock.prompt.map((p, idx) => (idx === promptIdx ? response.message : p)),
        })
      );
    } catch (e) {
      console.error('Error while fetching popular shows');
      throw e;
    }
  };

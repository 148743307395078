import { AudioGenerationActions, AudioGenerationActionTypes } from '../Actions/AudioGeneration';
import { SavedAudioDTO } from '../../Models/SavedAudio';

export interface AudioGenerationReducerState {
  audioGenerationsPollingData: {
    pendingGenerationIds: string[];
    pendingGenerationStates: string[];
    savedAudios: SavedAudioDTO[];
    referenceIdToSavedAudiosMap: Record<string, SavedAudioDTO[]>;
  };
}

export const initialAudioGenerationState: AudioGenerationReducerState = {
  audioGenerationsPollingData: {
    pendingGenerationIds: [],
    pendingGenerationStates: [],
    savedAudios: [],
    referenceIdToSavedAudiosMap: {},
  },
};

const audioGenerationReducer = (
  state: AudioGenerationReducerState = initialAudioGenerationState,
  action: AudioGenerationActionTypes
): AudioGenerationReducerState => {
  switch (action.type) {
    case AudioGenerationActions.ADD_PENDING_AUDIO_GENERATION: {
      const { payload: generationIds } = action;
      const setOfIds = new Set(state.audioGenerationsPollingData.pendingGenerationIds);

      return {
        ...state,
        audioGenerationsPollingData: {
          ...state.audioGenerationsPollingData,
          pendingGenerationIds: [
            ...state.audioGenerationsPollingData.pendingGenerationIds,
            ...generationIds.filter(id => !setOfIds.has(id)),
          ],
          pendingGenerationStates: state.audioGenerationsPollingData.pendingGenerationStates,
        },
      };
    }
    case AudioGenerationActions.REMOVE_PENDING_AUDIO_GENERATION: {
      const { payload: removeGenerationIds } = action;
      const updatedPendingIds = state.audioGenerationsPollingData.pendingGenerationIds.filter(
        id => !removeGenerationIds.includes(id)
      );
      return {
        ...state,
        audioGenerationsPollingData: {
          ...state.audioGenerationsPollingData,
          pendingGenerationIds: updatedPendingIds,
          pendingGenerationStates: state.audioGenerationsPollingData.pendingGenerationStates,
        },
      };
    }
    case AudioGenerationActions.ADD_MULTIPLE_SAVED_AUDIO_GENERATION: {
      const { payload } = action as { payload: SavedAudioDTO[] };
      const set = new Set(state.audioGenerationsPollingData.savedAudios.map(audio => audio.id));
      const referenceIdToSavedAudiosMap = payload.reduce(
        (acc, audio) => {
          if (audio.audioUrl) {
            if (!acc[audio.referenceId]) {
              acc[audio.referenceId] = [];
            }
            // check if already exists
            if (!set.has(audio.id)) {
              acc[audio.referenceId].push(audio);
            }
          }
          return acc;
        },
        { ...state.audioGenerationsPollingData.referenceIdToSavedAudiosMap }
      );
      return {
        ...state,
        audioGenerationsPollingData: {
          ...state.audioGenerationsPollingData,
          savedAudios: [
            ...state.audioGenerationsPollingData.savedAudios,
            ...payload.filter(audio => !set.has(audio.id)),
          ],
          referenceIdToSavedAudiosMap,
        },
      };
    }
    default:
      return state;
  }
};

export default audioGenerationReducer;

export const BANNER_THUMBNAIL_V2_HEIGHT = 1000;
export const BANNER_THUMBNAIL_V2_WIDTH = 800;

export const WIDGET_THUMBNAIL_V2_HEIGHT = 440;
export const WIDGET_THUMBNAIL_V2_WIDTH = 280;

export const DETAIL_PAGE_THUMBNAIL_V2_HEIGHT = 582;
export const DETAIL_PAGE_THUMBNAIL_V2_WIDTH = 824;

export const CONTINUE_READING_THUMBNAIL_V2_HEIGHT = 200;
export const CONTINUE_READING_THUMBNAIL_V2_WIDTH = 200;

export const CONTINUE_READING_THUMBNAIL_V2_ASPECT_RATIO = 1;
export const BANNER_THUMBNAIL_V2_ASPECT_RATIO = 4 / 5;
export const WIDGET_THUMBNAIL_V2_ASPECT_RATIO = 10 / 16;

export const SERIES_END_THUMBNAIL_WIDTH = 800;
export const SERIES_END_THUMBNAIL_ASPECT_RATIO = 4 / 6;

export const REELS_THUMBNAIL_4X5_HEIGHT = 1250;
export const REELS_THUMBNAIL_4X5_WIDTH = 1000;
export const REELS_THUMBNAIL_4X5_ASPECT_RATIO = 4 / 5;

export const REELS_THUMBNAIL_5X8_HEIGHT = 1600;
export const REELS_THUMBNAIL_5X8_WIDTH = 1000;
export const REELS_THUMBNAIL_5X8_ASPECT_RATIO = 5 / 8;
import httpClient from '../../Utils/HttpClient';
import {
  adminApiPrefix,
  aiApiPrefix,
  apiPrefix,
  clusterApiPrefix,
  cmsApiPrefix,
  genreApiPrefix,
  readerApiPrefix,
  reelsApiPrefix,
  showApiPrefix,
  studioApiPrefix,
  stylesApiPrefix,
  tagApiPrefix,
  tropeApiPrefix,
  v2Prefix,
} from '../../Constants/Constants';
import {
  DashEntityType,
  RBACEntryCreationRequest,
  RBACUpdateRoleRequest,
  RBACUserRemoveRequest,
  ReactionType,
  Show,
  ShowDetailsRequest,
  ShowDTO,
  ShowMetaDataDTO,
  ShowMetaDataType,
} from '../../Models/Show';
import { StyleLiveConfigResponse } from '../../Models/Styles';

export const fetchShow = async (showId: string, showDetailsRequest: ShowDetailsRequest) => {
  try {
    return httpClient.post(`${studioApiPrefix}${showApiPrefix}/${showId}`, showDetailsRequest, {
      resourceId: showId,
    });
  } catch (error) {
    console.error(`Unable to fetch show ${showId}`);
    return null;
  }
};

export const fetchCommentsCountForShow = async (showId: string) => {
  try {
    return httpClient.get(`${readerApiPrefix}/user-comment/count/show/${showId}`);
  } catch (error) {
    console.error(`Unable to fetch comments count for show ${showId}`);
    return null;
  }
};

export const fetchCountForBulkEntity = async (entityIds: string[], entityType: DashEntityType) => {
  try {
    return httpClient.post(
      `${readerApiPrefix}/user-rating/count/entityType/${entityType}`,
      entityIds
    );
  } catch (error) {
    console.error(`Unable to fetch count for bulk entity ${entityIds}`);
    return null;
  }
};

export const fetchUserReactionForBulkEntity = async (
  entity: DashEntityType,
  reactionType: ReactionType,
  entityIds: string[]
) => {
  try {
    return httpClient.post(
      `${readerApiPrefix}/user-reaction/count/entityType/${entity}/reactionType/${reactionType}`,
      entityIds
    );
  } catch (error) {
    console.error(`Unable to fetch user reaction for bulk entity ${entityIds}`);
    return null;
  }
};
export const fetchViewsCountForShow = async (showIds: string[]) => {
  try {
    return httpClient.post(`${readerApiPrefix}/show/views`, showIds);
  } catch (error) {
    console.error(`Unable to fetch views count for shows`);
    return null;
  }
};

export const fetchAvailableGenres = async () => {
  try {
    return httpClient.get(`${cmsApiPrefix}${genreApiPrefix}`);
  } catch (error) {
    console.error(`Unable to fetch available genres`);
    return null;
  }
};

export const fetchAvailableTags = async () => {
  try {
    return httpClient.get(`${cmsApiPrefix}${tagApiPrefix}`);
  } catch (error) {
    console.error(`Unable to fetch available tags`);
    return null;
  }
};

export const fetchAvailableClusters = async () => {
  try {
    return httpClient.get(`${cmsApiPrefix}${clusterApiPrefix}`);
  } catch (error) {
    console.error(`Unable to fetch available cluster`);
    return null;
  }
};

export const fetchAvailableTropes = async () => {
  try {
    return httpClient.get(`${cmsApiPrefix}${tropeApiPrefix}`);
  } catch (error) {
    console.error(`Unable to fetch available tropes`);
    return null;
  }
};

export const updateShowDTO = async (showId: string, showData: ShowDTO) => {
  try {
    return httpClient.put(
      `${studioApiPrefix}${showApiPrefix}/id/${showId}`,
      showData,
      {
        resourceId: showId,
      },
      false
    );
  } catch (error) {
    console.error(`Unable to update show ${showId}`);
    throw error;
  }
};

export const availableShowRoles = async () => {
  try {
    return httpClient.get(`${apiPrefix}${studioApiPrefix}${adminApiPrefix}${showApiPrefix}/roles`);
  } catch (error) {
    console.error('Unable to fetch roles available for show');
    return null;
  }
};

export const fetchShowStudioUsers = async (showId: string) => {
  try {
    return httpClient.get(
      `${apiPrefix}${studioApiPrefix}${adminApiPrefix}${showApiPrefix}/users/${showId}`,
      {
        resourceId: showId,
      },
      false
    );
  } catch (error) {
    console.error(`Unable to fetch show studio users for ${showId}`);
    return null;
  }
};

export const addUsersToShow = async (
  rbacEntryCreationRequest: RBACEntryCreationRequest,
  showId: string
) => {
  try {
    return httpClient.put(
      `${apiPrefix}${studioApiPrefix}${adminApiPrefix}${showApiPrefix}/users/add-users`,
      rbacEntryCreationRequest,
      {
        resourceId: showId,
      },
      false
    );
  } catch (error) {
    console.error(`Unable to add users to show ${rbacEntryCreationRequest.showId}`);
    return null;
  }
};

export const removeUserFromShow = async (
  rbacUserRemoveRequest: RBACUserRemoveRequest,
  showId: string
) => {
  try {
    return httpClient.delete(
      `${apiPrefix}${studioApiPrefix}${adminApiPrefix}${showApiPrefix}/users/remove-user`,
      rbacUserRemoveRequest,
      {
        resourceId: showId,
      }
    );
  } catch (error) {
    console.error(`Unable to remove user from show ${rbacUserRemoveRequest.showId}`);
    return null;
  }
};

export const updateUserAccessForShow = async (
  rbacUpdateRoleRequest: RBACUpdateRoleRequest,
  showId: string
) => {
  try {
    return httpClient.post(
      `${apiPrefix}${studioApiPrefix}${adminApiPrefix}${showApiPrefix}/users/update-access`,
      rbacUpdateRoleRequest,
      {
        resourceId: showId,
      }
    );
  } catch (error) {
    console.error(`Unable to update user access for show ${rbacUpdateRoleRequest.showId}`);
    return null;
  }
};

export const fetchShowStyles = async () => {
  try {
    return httpClient.get(`${apiPrefix}${v2Prefix}${aiApiPrefix}${stylesApiPrefix}`);
  } catch (error) {
    console.error(`Unable to fetch show styles`);
    return null;
  }
};

export const fetchStyleLiveConfig = async (): Promise<StyleLiveConfigResponse> => {
  try {
    return httpClient.get(`${apiPrefix}${v2Prefix}${aiApiPrefix}${stylesApiPrefix}/live`);
  } catch (error) {
    console.error(`Unable to fetch style live config`);
    throw error;
  }
};

export const updateShowMetadata = async (
  showId: string,
  updatedMetadataList: ShowMetaDataDTO[],
  silent?: boolean
) => {
  try {
    return httpClient.put(
      `${studioApiPrefix}${showApiPrefix}/id/${showId}/metadata`,
      updatedMetadataList,
      {
        resourceId: showId,
      },
      !silent
    );
  } catch (error) {
    console.error(
      `Unable to update metadata for show : ${showId} with metadata : ${updatedMetadataList}`
    );
    return null;
  }
};

export const deleteShowMetadata = async (
  showId: string,
  type: ShowMetaDataType,
  silent?: boolean
) => {
  try {
    return httpClient.delete(
      `${studioApiPrefix}${showApiPrefix}/id/${showId}/metadata`,
      type,
      {
        resourceId: showId,
      },
      !silent
    );
  } catch (error) {
    console.error(`Unable to update metadata for show : ${showId} with metadata type : ${type}`);
    return null;
  }
};

export const fetchShowMetadata = async (showId: string): Promise<ShowMetaDataDTO[]> => {
  try {
    return httpClient.get(`${studioApiPrefix}${showApiPrefix}/metadata/${showId}`);
  } catch (error) {
    console.error(`Unable to fetch metadata for show : ${showId}`);
    throw error;
  }
};

export const createNewShow = async (showData: ShowDTO): Promise<Show> => {
  try {
    return httpClient.post(`${studioApiPrefix}${showApiPrefix}/create`, showData);
  } catch (error) {
    console.error(`Unable to create new show`);
    throw error;
  }
};

export const publishShow = async (showId: string) => {
  try {
    return httpClient.post(`${studioApiPrefix}${showApiPrefix}/publish`, {
      showId: showId,
    });
  } catch (error) {
    console.error(`Unable to publish show`);
    throw error;
  }
};

export const insertBlacklist = async (showId: string, countryCodes: string[]) => {
  try {
    return httpClient.post(`${reelsApiPrefix}${apiPrefix}/v1/shows/blacklist`, {
      showId,
      countryCodes,
    });
  } catch (error) {
    console.error(`Unable to insert blacklist for show ${showId}`);
    return null;
  }
};

export const getBlacklist = async (showId: string) => {
  try {
    return httpClient.get(`${reelsApiPrefix}${apiPrefix}/v1/shows/blacklist/${showId}`);
  } catch (error) {
    console.error(`Unable to fetch blacklist for show ${showId}`);
    return null;
  }
};

export const updateBlacklist = async (showId: string, countryCodes: string[]) => {
  try {
    return httpClient.post(`${reelsApiPrefix}${apiPrefix}/v1/shows/blacklist/update`, {
      showId,
      countryCodes,
    });
  } catch (error) {
    console.error(`Unable to update blacklist for show ${showId}`);
    return null;
  }
};

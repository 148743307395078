import { apiPrefix, imageAnalysisPrefix, v2Prefix } from '../../Constants/Constants';
import httpClient from '../../Utils/HttpClient';
import {
  ImageAnalysisRequest,
  ImageAnalysisResponse,
} from '../../Models/ImageAnalysis/ImageAnalysisAPI';

export const getImageAnalysis = async (
  imageAnalysisRequest: ImageAnalysisRequest
): Promise<ImageAnalysisResponse> => {
  try {
    return httpClient.post(`${apiPrefix}${v2Prefix}${imageAnalysisPrefix}`, imageAnalysisRequest, {
      resourceId: imageAnalysisRequest.showId,
    });
  } catch (error) {
    console.error(`Unable to analyse image for ${imageAnalysisRequest.imageId}`);
    throw new Error('Unable to analyse image');
  }
};

export const getAllImageAnalysis = async (
  showId: string,
  episodeId: string
): Promise<ImageAnalysisResponse[]> => {
  try {
    return httpClient.get(
      `${apiPrefix}${v2Prefix}${imageAnalysisPrefix}/show/${showId}/episode/${episodeId}`,
      {
        resourceId: showId,
      }
    );
  } catch (error) {
    console.error(`Unable to fetch all image analysis for ${showId}`);
    throw new Error('Unable to fetch all image analysis');
  }
};

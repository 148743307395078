import { AudioGenerationResponse, Txt2AudioGenerationRequest } from '../../Models/AudioGeneration';
import HttpClient from '../../Utils/HttpClient';
import {
  aiApiPrefix,
  apiPrefix,
  audioPrefix,
  savedAudioPrefix,
  savedVideoPrefix,
  studioApiPrefix,
  v2Prefix,
  videoPrefix,
} from '../../Constants/Constants';
import { SavedAudioDTO } from '../../Models/SavedAudio';
import {
  MarkSavedVideoFavouriteRequest,
  SavedVideoDTO,
  UploadVideoRequest,
} from '../../Models/VideoGeneration/SavedVideo';

export const getSavedVideoByShowIdAndEpisodeId = async ({
  showId,
  episodeId,
}: {
  showId: string;
  episodeId: string;
}): Promise<SavedVideoDTO[]> => {
  try {
    return HttpClient.get(
      `${apiPrefix}${studioApiPrefix}${savedVideoPrefix}/show/${showId}/episode/${episodeId}`,
      {
        resourceId: showId,
      }
    );
  } catch (error) {
    console.error(`Unable to fetch saved video by show id and episode id`);
    throw error;
  }
};

export const uploadVideoToEpisode = async (request: UploadVideoRequest) => {
  try {
    return HttpClient.post(`${apiPrefix}${studioApiPrefix}${savedVideoPrefix}/upload`, request, {
      resourceId: request.showId,
    });
  } catch (error) {
    console.error(`Unable to upload video to episode`);
    throw error;
  }
};

export const markSavedVideoAsFavourite = async (request: MarkSavedVideoFavouriteRequest) => {
  try {
    return HttpClient.put(`${apiPrefix}${studioApiPrefix}${savedVideoPrefix}/favourite`, request, {
      resourceId: request.showId,
    });
  } catch (error) {
    console.error(`Unable to mark saved video as favourite`);
    throw error;
  }
};

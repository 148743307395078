export const MIN_IMAGE_GENERATION_SIZE = 64;
export const MAX_IMAGE_GENERATION_SIZE = 1024;

export const MAX_UPSCALE_SIZE = 3072;

export const GENERATION_SIZE_STEPS = 16;
export const DEFAULT_HIGH_RES_FIX_STRENGTH = 0.7;

export const DEFAULT_INFERENCE_STEPS = 20;

export const DEFAULT_CHARACTER_WEIGHT = 0.65;

export const MAX_PRIORITY_QUEUE_SIZE = 5;

export enum TrainingStatusNotification {
  LOADING = 'Starting Training Process',
  DONE_LOADING = 'Your character has started training, please wait for 30 minutes',
}

export const NSFW_IMAGE =
  'https://content.dashtoon.ai/imported-images/ef4b2aa9-fe14-41d4-8c30-4be61f8a6729.png';

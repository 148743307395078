import { ThunkAction } from 'redux-thunk';
import { ReduxState } from '../ReduxInterface';
import { AnyAction } from 'redux';
import { UserQuotaResponse } from '../../Models/User';
import { fetchUserQuotas } from '../../Requests/Studio/User';
import { UserQuotaSubscriptionActions } from './UserQuotaSubscription';
import { ClothesLibraryResponse } from '../../Models/Clothes';
import { getAllClothes } from '../../Requests/Studio/Clothes';

export enum ClothesActions {
  SET_CLOTHES_LIBRARY = 'SET_CLOTHES_LIBRARY',
  SET_SELECTED_CLOTHES = 'SET_SELECTED_CLOTHES',
  SET_MASK_URL = 'SET_MASK_URL',
  SET_UPLOADED_CLOTH_IMAGE = 'SET_UPLOADED_CLOTH_IMAGE',
  SET_CREATE_CLOTH_MODAL_OPEN = 'SET_CREATE_CLOTH_MODAL_OPEN',
  UPDATE_CLOTH = 'UPDATE_CLOTH',
  ADD_CLOTH = 'ADD_CLOTH',
  RESET_ALL = 'RESET_ALL',
  DELETE_CLOTH = 'DELETE_CLOTH',
}

export const setClothesLibrary =
  (showId: string): ThunkAction<void, ReduxState, unknown, AnyAction> =>
  async dispatch => {
    dispatch({ type: ClothesActions.SET_CLOTHES_LIBRARY });

    try {
      const response: ClothesLibraryResponse[] | null = await getAllClothes(showId);
      dispatch({ type: ClothesActions.SET_CLOTHES_LIBRARY, payload: response });
    } catch (error) {
      dispatch({
        type: ClothesActions.SET_CLOTHES_LIBRARY,
        payload: null,
      });
    }
  };

import HttpClient from '../../Utils/HttpClient';
import { apiPrefix, v2Prefix } from '../../Constants/Constants';
import {
  PromptEnhanceRequest,
  TextEnhancerRequest,
  TextEnhancerResponse,
} from '../../Models/TextEnhancer';

export const enhancePrompt = async (
  request: PromptEnhanceRequest
): Promise<TextEnhancerResponse> => {
  try {
    return await HttpClient.post(`${apiPrefix}${v2Prefix}/chat/prompt-enhance`, {
      text: request.text,
      type: request.type,
    });
  } catch (e) {
    console.error('Error while fetching popular shows');
    throw e;
  }
};

export const enhanceText = async (
  textEnhancerRequest: TextEnhancerRequest
): Promise<TextEnhancerResponse> => {
  try {
    return await HttpClient.post(`${apiPrefix}${v2Prefix}/chat/text-enhance`, textEnhancerRequest);
  } catch (e) {
    console.error('Error while fetching popular shows');
    throw e;
  }
};

import { ClothesActions } from '../Actions/Clothes';

export interface ClothesAction {
  type: ClothesActions;
  payload?: any;
}

export const ClothesReducer = (state: any = {}, action: ClothesAction): any => {
  switch (action.type) {
    case ClothesActions.SET_CLOTHES_LIBRARY:
      return { ...state, clothesLibrary: action.payload! };
    case ClothesActions.SET_SELECTED_CLOTHES:
      return { ...state, selectedClothes: action.payload! };
    case ClothesActions.SET_MASK_URL:
      return { ...state, maskUrl: action.payload! };
    case ClothesActions.SET_UPLOADED_CLOTH_IMAGE:
      return { ...state, uploadedClothImage: action.payload! };
    case ClothesActions.UPDATE_CLOTH:
      //update cloth from clothes library
      const updatedClothesLibrary = state.clothesLibrary.map((cloth: any) => {
        if (cloth.id === action.payload.id) {
          return action.payload;
        }
        return cloth;
      });
      return { ...state, clothesLibrary: updatedClothesLibrary };
    case ClothesActions.ADD_CLOTH:
      return { ...state, clothesLibrary: [...state.clothesLibrary, action.payload] };
    case ClothesActions.SET_CREATE_CLOTH_MODAL_OPEN:
      return { ...state, createClothModalOpen: action.payload };
    case ClothesActions.DELETE_CLOTH:
      // delete cloth from clothes library
      const deletedClothesLibrary = state.clothesLibrary.filter((cloth: any) => {
        return cloth.id !== action.payload.id;
      });
      return {
        ...state,
        clothesLibrary: deletedClothesLibrary,
      };
    case ClothesActions.RESET_ALL:
      // set initial states
      return {
        clothesLibrary: state.clothesLibrary,
        selectedClothes: null,
        maskUrl: null,
        uploadedClothImage: null,
      };

    default:
      return state;
  }
};

import { ImageAnalysisActions } from '../Actions/ImageAnalysis';

export interface ImageAnalysisAction {
  type: ImageAnalysisActions;
  payload?: any;
}

export const ImageAnalysisReducer = (state: any = {}, action: ImageAnalysisAction): any => {
  switch (action.type) {
    case ImageAnalysisActions.SET_IMAGE_ANALYSIS_HISTORY:
      return { ...state, imageAnalysis: action.payload! };
    case ImageAnalysisActions.ADD_IMAGE_ANALYSIS_HISTORY:
      return { ...state, imageAnalysis: [...state.imageAnalysis, action.payload] };
    default:
      return state;
  }
};

import { SavedVideoActions } from '../../Actions/VideoGeneration/SavedVideo';
import {
  initialSavedVideoState,
  SavedVideoDTO,
  SavedVideoState,
} from '../../../Models/VideoGeneration/SavedVideo';

export interface SavedVideoAction {
  type: SavedVideoActions;
  payload?: any;
}

export const SavedVideoReducer = (
  state: SavedVideoState = initialSavedVideoState,
  action: SavedVideoAction
): SavedVideoState => {
  switch (action.type) {
    case SavedVideoActions.ADD_SAVED_VIDEO:
      return {
        ...state,
        savedVideos: [action.payload as SavedVideoDTO, ...state.savedVideos],
      };

    case SavedVideoActions.REMOVE_SAVED_VIDEO:
      return {
        ...state,
        savedVideos: state.savedVideos.filter(video => video.id !== action.payload), // Remove by ID
      };

    case SavedVideoActions.ADD_MULTIPLE_SAVED_VIDEO:
      return {
        ...state,
        savedVideos: [...(action.payload as SavedVideoDTO[]), ...state.savedVideos],
      };

    case SavedVideoActions.SET_SAVED_VIDEO:
      return {
        ...state,
        savedVideos: action.payload as SavedVideoDTO[],
      };
    case SavedVideoActions.RESET_SAVED_VIDEO:
      return initialSavedVideoState;

    case SavedVideoActions.SET_VIDEO_FAVOURITE_STATE:
      return {
        ...state,
        savedVideos: state.savedVideos.map(video =>
          video.id === action.payload.savedVideoId
            ? {
                ...video,
                isFavourite: action.payload.isFavourite,
              }
            : video
        ),
      };

    default:
      return state;
  }
};

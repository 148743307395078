import { AudioGenerationResponse, Txt2AudioGenerationRequest } from '../../Models/AudioGeneration';
import HttpClient from '../../Utils/HttpClient';
import {
  aiApiPrefix,
  apiPrefix,
  audioPrefix,
  savedAudioPrefix,
  studioApiPrefix,
  v2Prefix,
} from '../../Constants/Constants';
import { SavedAudioDTO, UploadAudioRequest } from '../../Models/SavedAudio';

export const getSavedAudioByShowIdAndEpisodeId = async ({
  showId,
  episodeId,
}: {
  showId: string;
  episodeId: string;
}): Promise<SavedAudioDTO[]> => {
  try {
    return HttpClient.get(
      `${apiPrefix}${studioApiPrefix}${savedAudioPrefix}/show/${showId}/episode/${episodeId}`,
      {
        resourceId: showId,
      }
    );
  } catch (error) {
    console.error(`Unable to fetch user wallet balance`);
    throw error;
  }
};

export const uploadAudioToEpisode = async (request: UploadAudioRequest): Promise<SavedAudioDTO> => {
  try {
    return HttpClient.post(`${apiPrefix}${studioApiPrefix}${savedAudioPrefix}/upload`, request, {
      resourceId: request.showId,
    });
  } catch (error) {
    console.error(`Unable to fetch user wallet balance`);
    throw error;
  }
};

import React, { ReactNode } from 'react';
import CustomButton from '../../../../Components/CustomButton/CustomButton';
import { TrackingEvents } from '../../../../Constants/TrackingEvents';
import { Touchable } from '@dashtoon/studio-components/dist/components/Touchable';
import { TrackingRequest } from '../../../../Utils/Analytics';
import { t } from 'i18next';

interface CreateNewDashtoonProps {
  isButton: boolean;
  children?: ReactNode;
  handleCreateNewShow: () => Promise<void>;
  label?: string;
}

const CreateNewDashtoon = ({
  isButton,
  children,
  handleCreateNewShow,
  label,
}: CreateNewDashtoonProps) => {
  return isButton ? (
    <CustomButton
      text={label ?? t('Create new Dashtoon')}
      onClick={e => {
        e.stopPropagation();
        handleCreateNewShow();
      }}
      variant={'contained'}
      hasBorder={false}
      height={'32px'}
      foregroundColor={'white'}
      padding={'8px'}
    />
  ) : (
    <div
      onClick={e => {
        e.stopPropagation();
        handleCreateNewShow();
      }}
    >
      {children}
    </div>
  );
};

export default CreateNewDashtoon;

import { GenerateImageType, getInitialImageGenerationState } from '../Models/GenerateImage';
import { SavedImagesInitialState } from '../Models/SaveImage'; // Import the initial state
import { createReduxStore } from './Thunk';
import { CharacterInitialState } from '../Models/Character';
import { InpaintInitialState } from '../Models/Inpaint';
import { getInitialUpscalerState } from '../Models/UpscalerState';
import { getTabSelectorInitState } from '../Models/TabSelector';
import { getInitialThemeState } from './Reducers/Theme';
import { getUserEnabledFeaturesInitState } from '../Models/UserEnabledFeatures';
import { getUserInitState } from '../Models/User';
import { getErrorInitState } from '../Models/Error';
import { DrawingInitialState } from '../Models/Drawing';
import { TextualEmbeddingInitialState } from '../Models/TextualEmbedding';
import { GenerationInitialState } from '../Models/Generate';
import { ShortcutsInitialState } from '../Models/Shortcuts';
import { GenerationMenuInitialState } from '../Models/GenerateImageV2';
import { PollImagesInitialState } from './Reducers/PollImages';
import { GenerationParamsInitialState } from './Reducers/GenerationParams';
import { LibrarySearchInitialState } from './Reducers/LibrarySearch';
import { InitialAppState } from './Reducers/AppState';
import { InitialExpressionsState } from '../Models/Expressions';
import {
  InitialCanvasState,
  InitialCharacterFromCanvasState,
  InitialShowDetailsModalState,
} from '../Models/CanvasState';
import { StudioCommentsInitialState } from './Reducers/StudioComments';
import { AllShowsInitialState } from './Reducers/AllShows';
import { AllEpisodesInitialState } from './Reducers/AllEpisodes';
import { TrainingStatusInitialState } from './Reducers/TrainingStatus';
import { DashverseInitialState } from '../Models/Dashverse';
import { CollectionInitialState } from './Reducers/Collection';
import { ControlNetInitialState } from './Reducers/ControlNet';
import { getLCMInitState } from './Reducers/LiveLCMReducer';
import { InitialShowDetailsCharacterViewState } from './Reducers/ShowDetailsCharacter';
import { RightClickMenuInitialState } from './Reducers/RightClickMenuState';
import { InitialShowDetailsState } from './Reducers/ShowDetails';
import { InitialWalkthroughState } from '../FTUE/Redux/Reducers/WalkthroughReducer';
import { initialFirstWalkthroughState } from '../FTUE/Redux/Reducers/FirstWalkthroughReducer';
import { TextEnhancerInitialState } from './Reducers/TextEnhancer';
import { initialStoryState } from './Reducers/StoryState';
import { initialThumbnailState } from './Reducers/Thumbnail';
import { initialConnectedAccountsState } from './Reducers/ConnectedAccountsState';
import { initialPromptBoxState } from './Reducers/PromptBoxCharacters';
import { initialUserMetadataState } from './Reducers/UserMetadataState';
import { initialStoryCrafterState } from './Reducers/StoryCrafterState';
import { initialShowUsersState } from './Reducers/ShowUsersState';
import { initialStoryCrafterStateV2 } from './Reducers/StoryCrafterStateV2';
import { initialQuotaState, initialSubscriptionState } from './Reducers/UserQuotaSubscription';
import { initialVideoModalState } from './Reducers/VideoModalState';
import { getInitialUploadState } from '../Models/UploadState';
import { getInitialGenerationSteps } from '../Models/GenerationSteps';
import { InitialGazeState } from '../Models/Gaze';
import audioGenerationReducer, {
  AudioGenerationReducerState,
  initialAudioGenerationState,
} from './Reducers/AudioGeneration';
import { InitialClothesLibraryState } from '../Models/Clothes';
import { InitialVideoGenerationState } from '../Models/VideoGeneration/VideoGeneration';
import { initialSavedVideoState } from '../Models/VideoGeneration/SavedVideo';
import { getInitialImageAnalysis } from '../Models/ImageAnalysis/ImageAnalysisAPI';

export const store = createReduxStore({
  showDetailsModalSizeState: InitialShowDetailsModalState,
  trainingStatusState: TrainingStatusInitialState,
  text2ImageGeneration: getInitialImageGenerationState(GenerateImageType.TXT2IMG),
  image2ImageGeneration: getInitialImageGenerationState(GenerateImageType.IMG2IMG),
  inpaintGeneration: getInitialImageGenerationState(GenerateImageType.INPAINT),
  savedImages: SavedImagesInitialState, // Add it to your initial state
  character: CharacterInitialState,
  inpaintImage: InpaintInitialState,
  upscaler: getInitialUpscalerState(),
  tabSelector: getTabSelectorInitState(),
  theme: getInitialThemeState(),
  userEnabledFeatures: getUserEnabledFeaturesInitState(),
  user: getUserInitState(),
  error: getErrorInitState(),
  drawing: DrawingInitialState,
  textualEmbedding: TextualEmbeddingInitialState,
  generation: GenerationInitialState,
  shortcuts: ShortcutsInitialState,
  generationMenuState: GenerationMenuInitialState,
  pollImages: PollImagesInitialState,
  generationParams: GenerationParamsInitialState,
  librarySearch: LibrarySearchInitialState,
  appState: InitialAppState,
  expressions: InitialExpressionsState,
  canvasState: InitialCanvasState,
  studioCommentsState: StudioCommentsInitialState,
  allShowsState: AllShowsInitialState,
  allEpisodesState: AllEpisodesInitialState,
  dashverseState: DashverseInitialState,
  collectionState: CollectionInitialState,
  controlNetState: ControlNetInitialState,
  liveLCMState: getLCMInitState(),
  rightClickMenuState: RightClickMenuInitialState,
  showDetailsCharacterViewState: InitialShowDetailsCharacterViewState,
  showDetailsState: InitialShowDetailsState,
  characterFromCanvasState: InitialCharacterFromCanvasState,
  walkthroughState: InitialWalkthroughState,
  firstWalkthroughState: initialFirstWalkthroughState,
  textEnhancerState: TextEnhancerInitialState,
  storyState: initialStoryState,
  storyCrafterState: initialStoryCrafterState,
  thumbnailState: initialThumbnailState,
  connectedAccountsState: initialConnectedAccountsState,
  promptBoxCharactersState: initialPromptBoxState,
  userMetadataState: initialUserMetadataState,
  showUsersState: initialShowUsersState,
  storyCrafterStateV2: initialStoryCrafterStateV2,
  subscriptionState: initialSubscriptionState,
  quotaState: initialQuotaState,
  videoModalState: initialVideoModalState,
  uploadState: getInitialUploadState(),
  generationSteps: getInitialGenerationSteps(),
  gazeState: InitialGazeState,
  audioGenerationData: initialAudioGenerationState,
  clothesLibraryState: InitialClothesLibraryState,
  videoGenerationState: InitialVideoGenerationState,
  savedVideoState: initialSavedVideoState,
  imageAnalysisHistory: getInitialImageAnalysis(),
});

import { AppStateActions, AppStateActionTypes } from '../Actions/AppState';
import { Show } from '../../Models/Show';
import { Genre } from '../../Models/Genre';
import { Trope } from '../../Models/Trope';
import { Episode } from '../../Models/Episode';
import { User } from 'firebase/auth';
import { ShowDetailsActions } from '../Actions/ShowDetails';

export interface AppState {
  enableShowModal: boolean;
  showPaymentsModal: boolean;
  showPaymentsModalV3: boolean;
  showCreditsUsageModal: boolean;
  showPaymentsModalText: string;
  showData?: Show;
  showInitShowDetails: boolean;
  showInpaintModal: boolean;
  showFTUEModal: boolean;
  showLoginModal: boolean;
  storyMode: boolean;
  userAppVersion: string;
  showStyleSelectionModal: boolean;
  showManageCharactersModal: boolean;
  showDashtoonSettingsModal: boolean;
  showManageTeamModal: boolean;
  showPublishModal: boolean;
  availableGenres: Genre[];
  availableTropes: Trope[];
  canUserEdit?: boolean;
  episodeData?: Episode;
  currUser?: User;
  showPublishedModal: boolean;
  publishNudgeClosed: boolean;
  newEpisodeNudgeClosed: boolean;
  showEditorNudge: boolean;
  editorNudgeClosed: boolean;
  autoThumbnailShowId: string;
  autoThumbnailEpisodeId: string;
  showCharacterStoryValidationModal: boolean;
}

export const InitialAppState: AppState = {
  enableShowModal: false,
  showPaymentsModal: false,
  showPaymentsModalV3: false,
  showCreditsUsageModal: false,
  showPaymentsModalText: '',
  showInitShowDetails: false,
  showInpaintModal: false,
  showFTUEModal: false,
  showLoginModal: false,
  storyMode: false,
  userAppVersion: localStorage.getItem('updatedVersion') ?? '',
  showStyleSelectionModal: false,
  showManageCharactersModal: false,
  showDashtoonSettingsModal: false,
  showManageTeamModal: false,
  showPublishModal: false,
  availableGenres: [],
  availableTropes: [],
  canUserEdit: false,
  showPublishedModal: false,
  publishNudgeClosed: window.localStorage.getItem('userHasPublished') === 'true',
  newEpisodeNudgeClosed: window.localStorage.getItem('userHasCreatedNewEpisode') === 'true',
  showEditorNudge: false,
  editorNudgeClosed: window.localStorage.getItem('userHasSeenEditorNudge') === 'true',
  autoThumbnailShowId: '',
  autoThumbnailEpisodeId: '',
  showCharacterStoryValidationModal: false,
};

export const AppStateReducer = (
  state: AppState = InitialAppState,
  action: AppStateActionTypes
): AppState => {
  switch (action.type) {
    case AppStateActions.OPEN_SHOW_DETAILS_MODAL:
      return { ...state, enableShowModal: action.payload };
    case AppStateActions.UPDATE_SHOW_DATA:
      return { ...state, showData: action.payload };
    case AppStateActions.OPEN_PAYMENTS_MODAL:
      return { ...state, showPaymentsModal: action.payload };
    case AppStateActions.OPEN_PAYMENTS_MODAL_V3:
      return { ...state, showPaymentsModalV3: action.payload };
    case AppStateActions.SET_PAYMENTS_MODAL_TEXT:
      return { ...state, showPaymentsModalText: action.payload };
    case AppStateActions.OPEN_INIT_SHOW_DETAILS_MODAL:
      return { ...state, showInitShowDetails: action.payload };
    case AppStateActions.OPEN_INPAINT_MODAL:
      return { ...state, showInpaintModal: action.payload };
    case AppStateActions.OPEN_FTUE_MODAL:
      return { ...state, showFTUEModal: action.payload };
    case AppStateActions.OPEN_LOGIN_MODAL:
      return { ...state, showLoginModal: action.payload };
    case AppStateActions.SET_STORY_MODE:
      return { ...state, storyMode: action.payload.storyMode };
    case AppStateActions.OPEN_STYLE_SELECTION_MODAL:
      return { ...state, showStyleSelectionModal: action.payload };
    case AppStateActions.OPEN_MANAGE_CHARACTERS_MODAL:
      return { ...state, showManageCharactersModal: action.payload };
    case AppStateActions.OPEN_DASHTOON_SETTINGS_MODAL:
      return { ...state, showDashtoonSettingsModal: action.payload };
    case AppStateActions.OPEN_MANAGE_TEAM_MODAL:
      return { ...state, showManageTeamModal: action.payload };
    case AppStateActions.SET_IS_PUBLISH_MODAL_OPEN:
      return { ...state, showPublishModal: action.payload };
    case AppStateActions.SET_AVAILABLE_GENRES:
      return { ...state, availableGenres: action.payload };
    case AppStateActions.SET_AVAILABLE_TROPES:
      return { ...state, availableTropes: action.payload };
    case AppStateActions.SET_CAN_USER_EDIT:
      return { ...state, canUserEdit: action.payload };
    case AppStateActions.SET_EPISODE_DATA:
      return { ...state, episodeData: action.payload };
    case AppStateActions.SET_USER:
      return { ...state, currUser: action.payload };
    case AppStateActions.SET_CREDITS_USAGE_MODAL:
      return { ...state, showCreditsUsageModal: action.payload };
    case AppStateActions.SET_PUBLISHED_MODAL:
      return { ...state, showPublishedModal: action.payload };
    case AppStateActions.OPEN_CHARACTER_STORY_VALIDATION_MODAL:
      return { ...state, showCharacterStoryValidationModal: action.payload };
    case AppStateActions.SET_PUBLISH_NUDGE_CLOSED:
      return { ...state, publishNudgeClosed: action.payload };
    case AppStateActions.SET_NEW_EPISODE_NUDGE_CLOSED:
      return { ...state, newEpisodeNudgeClosed: action.payload };
    case AppStateActions.SET_SHOW_EDITOR_NUDGE:
      return { ...state, showEditorNudge: action.payload };
    case AppStateActions.SET_EDITOR_NUDGE_CLOSED:
      return { ...state, editorNudgeClosed: action.payload };
    case AppStateActions.SET_AUTO_THUMBNAIL_APPLIED:
      return {
        ...state,
        autoThumbnailShowId: action.payload.autoThumbnailShowId,
        autoThumbnailEpisodeId: action.payload.autoThumbnailEpisodeId,
      };
    case AppStateActions.SET_EPISODE_PROJECT_STATUS:
      return {
        ...state,
        episodeData: state.episodeData
          ? { ...state.episodeData, studioMetadata: [action.payload.data] }
          : state.episodeData,
        showData: state.showData
          ? {
              ...state.showData,
              episodes: state.showData.episodes.map(e =>
                e.id === action.payload.episodeId
                  ? { ...e, studioMetadata: [action.payload.data] }
                  : e
              ),
            }
          : state.showData,
      };

    case AppStateActions.UPDATE_SHOW_METADATA: {
      return {
        ...state,
        showData: state.showData
          ? {
              ...state.showData,
              metadata:
                state.showData?.metadata.map(m =>
                  m.type === action.payload.type ? { ...m, value: action.payload.value } : m
                ) ?? [],
            }
          : state.showData,
      };
    }
    default:
      return state;
  }
};

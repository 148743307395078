import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './Redux/Store';
import MetaPixel from './Utils/metaPixel';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { HotkeysProvider } from 'react-hotkeys-hook';
import { ConfigProvider } from 'antd';
import ClarityTracker from './Utils/ClarityTracker';
import { NotificationProvider } from './Provider/NotificationProvider';
import GATracker from './Utils/GATracker';
import { trackEvent } from './Utils/Analytics';
import { TrackingEvents } from './Constants/TrackingEvents';
import { AuthProvider } from './Provider/AuthProvider';
import { CountryCodeProvider } from './Provider/CountryCodeProvider';
import { BrowserRouter } from 'react-router-dom';
import CustomCircularLoader from './Components/CustomCircularLoader/CustomCircularLoader';
import { ErrorBoundary, ErrorScreen } from '@dashtoon/tldraw';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Suspense fallback={<CustomCircularLoader size={40} />}>
    <HotkeysProvider initiallyActiveScopes={['settings']}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <CountryCodeProvider>
            <AuthProvider>
              <NotificationProvider>
                <MetaPixel />
                <ClarityTracker />
                <GATracker />
                <ConfigProvider
                  theme={{
                    components: {
                      Message: {
                        zIndexPopupBase: 99999999999,
                      },
                    },
                  }}
                >
                  <BrowserRouter basename={'/studio'}>
                    <App />
                  </BrowserRouter>
                </ConfigProvider>
              </NotificationProvider>
            </AuthProvider>
          </CountryCodeProvider>
        </Provider>
      </I18nextProvider>
    </HotkeysProvider>
  </Suspense>
);

function sendToAnalytics({ name, value }: any) {
  trackEvent(
    {
      event: TrackingEvents.webVitals,
      properties: {
        name: name,
        value: value,
      },
    },
    'CREATOR'
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(sendToAnalytics);

import { Episode } from './Episode';

export enum EpisodeState {
  WIP = 'WIP',
  READY_TO_REVIEW = 'READY_TO_REVIEW',
  PUBLISHED = 'PUBLISHED',
  SCHEDULED = 'SCHEDULED',
  DELETED = 'DELETED',
  BANK = 'BANK',
}

export interface Show {
  id: string;
  name: string;
  creator: string;
  createdAt: Date;
  description: string;
  genre: string;
  trope: string;
  rating: number;
  state: ShowState;
  updatedAt: Date;
  thumbNailUrl: string | null;
  category: ShowCategory;
  editorScore: number;
  createdBy: string | null;
  episodes: Episode[];
  type: ShowType;
  metadata: ShowMetaData[];
  episodeCount: number | null;
  tag: string;
  cluster: string;
  publisher: string;
  author: string;
}

export interface ShowStats {
  showId: string;
  likes: number;
  comments: number;
  views?: number;
}

export interface ShowWithViewsAndEpisodeCount {
  id: string;
  name: string;
  creator: string;
  description: string;
  genre: string;
  trope: string;
  thumbNailUrl?: string;
  views: number;
  episodeCount: number;
  metadata: ShowMetaData[];
}

export enum ShowCategory {
  UGC = 'UGC',
  PUGC = 'PUGC',
  PGC = 'PGC',
}

export enum ShowContentDerivative {
  ORIGINAL = 'ORIGINAL',
  REINTERPRETATION = 'REINTERPRETATION',
  PARODY = 'PARODY',
  FAN_ART = 'FAN_ART',
}

export enum ShowMetaDataType {
  DAILY_CHALLENGE,
  BANNER_COMIC,
  STAFF_PICKS,
  COMIC_STYLE = 'COMIC_STYLE',
  BANNER_THUMBNAIL_V2 = 'BANNER_THUMBNAIL_V2',
  WIDGET_THUMBNAIL_V2 = 'WIDGET_THUMBNAIL_V2',
  DETAIL_PAGE_THUMBNAIL_V2 = 'DETAIL_PAGE_THUMBNAIL_V2',
  CONTINUE_READING_THUMBNAIL_V2 = 'CONTINUE_READING_THUMBNAIL_V2',
  ENABLE_STYLE_WEIGHTS = 'ENABLE_STYLE_WEIGHTS',
  DEFAULT_STYLE_WEIGHT = 'DEFAULT_STYLE_WEIGHT',
  STYLE_NAME = 'STYLE_NAME',
  DEFAULT_CFG = 'DEFAULT_CFG',
  DEFAULT_WIDTH = 'DEFAULT_WIDTH',
  DEFAULT_HEIGHT = 'DEFAULT_HEIGHT',
  CONTENT_TYPE = 'CONTENT_TYPE',
  MAX_GENERATION_DIMENSION = 'MAX_GENERATION_DIMENSION',
  DEFAULT_GENERATION_STEPS = 'DEFAULT_GENERATION_STEPS',
  DEFAULT_CHARACTER_WEIGHT = 'DEFAULT_CHARACTER_WEIGHT',
  FAST_PASS_ENABLED = 'FAST_PASS_ENABLED',
  IS_TRIAL_SHOW = 'IS_TRIAL_SHOW',
  DAILY_FAST_PASS_EPISODE_COUNT = 'DAILY_FAST_PASS_EPISODE_COUNT',
  MATURITY_RATING = 'MATURITY_RATING',
  CONTENT_DERIVATIVE = 'CONTENT_DERIVATIVE',
  DEPICTION = 'DEPICTION',
  ALTERATION = 'ALTERATION',
  POST_CREDIT_THUMBNAILS = 'POST_CREDIT_THUMBNAILS',
  END_CREDIT_PANELS = 'END_CREDIT_PANELS',
  IS_VIDEO_COMIC = 'IS_VIDEO_COMIC',
  STUDIO_ASPECT_RATIO_SETTING = 'STUDIO_ASPECT_RATIO_SETTING',
  REELS_THUMBNAIL_4X5 = 'THUMBNAIL_4X5',
  REELS_THUMBNAIL_5x8 = 'THUMBNAIL_5X8',
  LANGUAGE = 'LANGUAGE',
  SUBTITLE_LANGUAGE = 'SUBTITLE_LANGUAGE',
}

export enum StudioShowAspectRatioSettingEnum {
  DEFAULT = 'DEFAULT',
  ASPECT_RATIO_9_16 = 'ASPECT_RATIO_9_16',
  ASPECT_RATIO_5_4 = 'ASPECT_RATIO_5_4',
  ASPECT_RATIO_4_5 = 'ASPECT_RATIO_4_5',
}

export enum MaturityRating {
  PG_13 = '13',
  RATED_16 = '16',
  RATED_R = '18',
}

export enum ShowContentType {
  WEBTOON = 'WEBTOON',
  COMIC = 'COMIC',
}

export enum ShowStyle {
  REALISTIC_COMIC,
}

export interface ShowMetaData {
  id: string;
  showId: string;
  type: ShowMetaDataType;
  value: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface ShowMetaDataDTO {
  type: ShowMetaDataType;
  value?: string;
}

export enum ShowType {
  ONE_SHOT = 'ONE_SHOT',
  SERIALIZED = 'SERIALIZED',
}

export enum ShowState {
  UNPUBLISHED = 'UNPUBLISHED',
  READY_TO_REVIEW = 'READY_TO_REVIEW',
  PUBLISHED = 'PUBLISHED',
  SCHEDULED = 'SCHEDULED',
  DELETED = 'DELETED',
}

export interface ShowDTO {
  name: string;
  creator: string;
  thumbNailImageData: string | null;
  description: string;
  genre: string;
  trope: string;
  createdBy: string | null;
  metadata: ShowMetaDataDTO[];
  type: ShowType;
  tag: string;
  cluster: string;
  publisher: string;
  author: string;
}

export interface ShowUpdateDTO {
  id: string;
  thumbNailImageData: string | null;
}

export interface ShowDetailsRequest {
  requestedMetadata: string[];
  includeEpisodes?: boolean;
  includeEpisodeUserMapping?: boolean;
}

export interface ShowClassifiers {
  showIds: string[];
  genreList: string[];
  types: ShowType[];
  metadataList: ShowMetaDataDTO[];
  categories: ShowCategory[];
  creators: string[];
  states: ShowState[];
  sortByEditorScore: boolean;
  sortByCreatedAt: boolean;
  count: number;
  offset: number;
}

export interface AvailableRolesResponse {
  roles: string[];
}

export interface ShowStudioUser {
  name: string;
  email: string;
  role: string;
  shows: string[];
  photoUrl?: string | null;
}

export enum RBACRole {
  OWNER = 'OWNER',
  MANAGER = 'MANAGER',
  CREATOR = 'CREATOR',
  TRAINER = 'TRAINER',
}

export interface ShowStudioUsersResponse {
  users: ShowStudioUser[];
}

export interface RBACEntryCreationRequest {
  showId: string;
  users: RBACUserListGroup[];
}

export interface RBACUserListGroup {
  role: string;
  userIds: string[];
}

export interface RBACUserRemoveRequest {
  showId: string;
  userEmail: string;
  role: string;
}

export interface RBACUpdateRoleRequest {
  showId: string;
  userEmail: string;
  newRole: string;
}

export enum DashEntityType {
  SHOW = 'SHOW',
  EPISODE = 'EPISODE',
  PANEL = 'PANEL',
  CHARACTER = 'CHARACTER',
}

export enum ReactionType {
  LIKE = 'LIKE',
  DISLIKE = 'DISLIKE',
}

export enum LANG_CODES {
  ENGLISH = 'en',
  HINDI = 'hi',
  CHINESE = 'zh',
}

export const getLangCode = (lang: string): string => {
  switch (lang) {
    case 'en':
      return 'English';
    case 'hi':
      return 'Hindi';
    case 'zh':
      return 'Chinese';
    default:
      return 'English';
  }
};

import {
  SavedVideoDTO,
  SavedVideoMetadata,
  SavedVideoMetadataTypes,
  UploadVideoRequest,
} from './SavedVideo';
import { GenerationType, UserGenerationsData } from '../PollRequests';
import { SaveImageResponse } from '../SaveImage';
import { getInitVideoConfigState, IVideoGenConfig } from './VideoGenConfig';
import {
  LipSyncVideoInputSource,
  VideoGenMode,
  VideoGenModes,
} from '../../Components/CanvasV3/VideoMode/RightBar/VideoGenerationMenu/VideoGenMode';
import { AppDispatch } from '../../Hooks/useTypedDispatch';
import { ReduxState } from '../../Redux/ReduxInterface';
import { generateLipSyncVideo } from '../../Requests/Studio/VideoGeneration';
import { UserQuotaSubscriptionActions } from '../../Redux/Actions/UserQuotaSubscription';
import { addPendingVideoGeneration } from '../../Redux/Actions/VideoGeneration/VideoGeneration';
import { uploadVideoToEpisode } from '../../Requests/Studio/SavedVideo';

export enum VideoModels {
  Kling_1_Pro = 'Kling 1 Pro',
  Kling_1_5_Pro = 'Kling 1.5 Pro',
  Kling_1_6_Pro = 'Kling 1.6 Pro',
  I2V_01_LIVE_MINIMAX = 'I2V 01 Live Minimax',
  COG_X = 'Cog X',
  VEO2 = 'Veo 2',
  SYNC_LIPSYNC = 'Sync Lipsync',
  LATENT_SYNC = 'Latent Sync',
  RAY_2 = 'Ray 2',
  WAN_PRO = 'Wan Pro',
  WAN_I2V = 'Wan I2V',
}

export enum VideoModelsIds {
  KLING_1_PRO = 'kling_1_pro',
  KLING_1_5_PRO = 'kling_1.5_pro',
  KLING_1_6_PRO = 'kling_1.6_pro',
  I2V_01_LIVE_MINIMAX = 'i2v_01_live_minimax',
  VEO2 = 'VEO2',
  COG_VIDEO_X = 'cog_video_x',
  SYNC_LIPSYNC = 'sync-lipsync',
  LATENT_SYNC = 'latentsync',
  RAY_2 = 'RAY_2',
  WAN_PRO = 'wan_pro',
  WAN_I2V = 'wan_i2v',
}

export const getModelText = (model: VideoModelsIds): string => {
  switch (model) {
    case VideoModelsIds.KLING_1_PRO:
      return VideoModels.Kling_1_Pro;
    case VideoModelsIds.KLING_1_5_PRO:
      return VideoModels.Kling_1_5_Pro;
    case VideoModelsIds.KLING_1_6_PRO:
      return VideoModels.Kling_1_6_Pro;
    case VideoModelsIds.I2V_01_LIVE_MINIMAX:
      return VideoModels.I2V_01_LIVE_MINIMAX;
    case VideoModelsIds.COG_VIDEO_X:
      return VideoModels.COG_X;
    case VideoModelsIds.VEO2:
      return VideoModels.VEO2;
    case VideoModelsIds.SYNC_LIPSYNC:
      return VideoModels.SYNC_LIPSYNC;
    case VideoModelsIds.LATENT_SYNC:
      return VideoModels.LATENT_SYNC;
    case VideoModelsIds.RAY_2:
      return VideoModels.RAY_2;
    case VideoModelsIds.WAN_PRO:
      return VideoModels.WAN_PRO;
    case VideoModelsIds.WAN_I2V:
      return VideoModels.WAN_I2V;
    default:
      return '';
  }
};

export const getModelId = (model: VideoModels): VideoModelsIds => {
  switch (model) {
    case VideoModels.Kling_1_Pro:
      return VideoModelsIds.KLING_1_PRO;
    case VideoModels.Kling_1_5_Pro:
      return VideoModelsIds.KLING_1_5_PRO;
    case VideoModels.Kling_1_6_Pro:
      return VideoModelsIds.KLING_1_6_PRO;
    case VideoModels.I2V_01_LIVE_MINIMAX:
      return VideoModelsIds.I2V_01_LIVE_MINIMAX;
    case VideoModels.COG_X:
      return VideoModelsIds.COG_VIDEO_X;
    case VideoModels.VEO2:
      return VideoModelsIds.VEO2;
    case VideoModels.SYNC_LIPSYNC:
      return VideoModelsIds.SYNC_LIPSYNC;
    case VideoModels.LATENT_SYNC:
      return VideoModelsIds.LATENT_SYNC;
    case VideoModels.RAY_2:
      return VideoModelsIds.RAY_2;
    case VideoModels.WAN_PRO:
      return VideoModelsIds.WAN_PRO;
    case VideoModels.WAN_I2V:
      return VideoModelsIds.WAN_I2V;
    default:
      return VideoModelsIds.KLING_1_5_PRO;
  }
};

export enum VideoDuration {
  FIVE_SECONDS = '5',
  TEN_SECONDS = '10',
}

export const getDurationText = (duration: VideoDuration): string => {
  switch (duration) {
    case VideoDuration.FIVE_SECONDS:
      return '5 sec';
    case VideoDuration.TEN_SECONDS:
      return '10 sec';
    default:
      return '';
  }
};

export enum VideoAspectRatio {
  NINE_BY_SIXTEEN = '9:16',
  SIXTEEN_BY_NINE = '16:9',
}

export const getAspectRatioText = (aspectRatio: VideoAspectRatio): string => {
  switch (aspectRatio) {
    case VideoAspectRatio.NINE_BY_SIXTEEN:
      return '9:16';
    case VideoAspectRatio.SIXTEEN_BY_NINE:
      return '16:9';
    default:
      return '';
  }
};

export enum InitImageType {
  FIRST_FRAME = 'FIRST_FRAME',
  LAST_FRAME = 'LAST_FRAME',
  MIDDLE_FRAME = 'MIDDLE_FRAME',
}

export const initImageEnumToTextMap: Record<InitImageType, string> = {
  [InitImageType.FIRST_FRAME]: 'First Frame',
  [InitImageType.LAST_FRAME]: 'Last Frame',
  [InitImageType.MIDDLE_FRAME]: 'Middle Frame',
};

export interface InitImage {
  type: InitImageType;
  id: number;
  url: string;
}

export interface BulkInterfacePromptRequest {
  prompt: string[];
  negativePrompt: string[];
  image: { imageUrl: string; id: number; type: InitImageType }[];
}

export interface BulkVideoGenerateMode {
  enabled: boolean;
  promptData: BulkInterfacePromptRequest[];
}

export interface BulkLipSyncInputData {
  audioUrls: string[];
  videoUrl: string;
  videoThumbnailUrl: string;
  videoGenerationId: string;
}

export interface BulkLipSyncGenerateMode {
  inputData: BulkLipSyncInputData[];
}

export interface VideoGenerationState {
  model: VideoModels;
  prompt: string;
  duration: VideoDuration;
  aspectRatio: VideoAspectRatio;
  initImages: InitImage[];
  batchCount: number;
  metadata: SavedVideoMetadata[];
  videoGenerationsPollingData: {
    pendingGenerationIds: string[];
    pendingGenerationStates: UserGenerationsData[];
    referenceIdToSavedVideosMap: Record<string, SavedVideoDTO[]>;
  };
  selectedVideo: SavedVideoDTO | null;
  selectedPendingGeneration: string | null;
  bulkGenerateMode: BulkVideoGenerateMode;
  negativePrompt: string;
  numInferenceSteps: number;
  numFrames: number;
  guidanceScale: number;
  exportFps: number;
  videoGenConfig: IVideoGenConfig[];
  genMode: VideoGenMode;
  lipSyncInputSource: LipSyncVideoInputSource;
  lipSyncBulkGenerationData: BulkLipSyncGenerateMode;
  loopEnabled?: boolean;
}

export const InitialVideoGenerationState: VideoGenerationState = {
  model: VideoModels.Kling_1_5_Pro,
  prompt: '',
  duration: VideoDuration.FIVE_SECONDS,
  aspectRatio: VideoAspectRatio.NINE_BY_SIXTEEN,
  initImages: [],
  batchCount: 1,
  metadata: [],
  videoGenerationsPollingData: {
    pendingGenerationIds: [],
    pendingGenerationStates: [],
    referenceIdToSavedVideosMap: {},
  },
  selectedVideo: null,
  selectedPendingGeneration: null,
  bulkGenerateMode: {
    enabled: true,
    promptData: [
      {
        prompt: [''],
        negativePrompt: [''],
        image: [
          { imageUrl: '', id: 1, type: InitImageType.FIRST_FRAME },
          { imageUrl: '', id: 2, type: InitImageType.MIDDLE_FRAME },
          { imageUrl: '', id: 3, type: InitImageType.LAST_FRAME },
        ],
      },
    ],
  },
  negativePrompt: '',
  numInferenceSteps: 50,
  guidanceScale: 7,
  exportFps: 16,
  videoGenConfig: getInitVideoConfigState(),
  genMode: VideoGenModes[0],
  lipSyncInputSource: LipSyncVideoInputSource.Audio,
  lipSyncBulkGenerationData: {
    inputData: [
      {
        audioUrls: [''],
        videoUrl: '',
        videoThumbnailUrl: '',
        videoGenerationId: '',
      },
    ],
  },
  loopEnabled: false,
  numFrames: 81,
};

export interface GenerateImg2VideoDTO {
  showId: string;
  episodeId: string;
  batchCount?: number;
  prompt: string;
  duration: string;
  initImages: InitImage[];
  model: VideoModelsIds;
  aspectRatio: string;
  metadata: SavedVideoMetadata[];
  width: number;
  height: number;
  negativePrompt: string;
  numInferenceSteps: number;
  guidanceScale: number;
  exportFps: number;
  loopEnabled?: boolean;
  numFrames?: number;
}

export interface InitLipSyncData {
  videoUrl: string;
  audioUrl: string;
}

export interface GenerateLipSyncVideoDTO {
  showId: string;
  episodeId: string;
  batchCount?: number;
  initLipSyncInputUrls: InitLipSyncData[];
  model: VideoModelsIds;
  metadata: SavedVideoMetadata[];
  width: number;
  height: number;
  aspectRatio: string;
}

export interface VideoGenerationResponse {
  generationIds: string[];
  walletBalance: number;
  estimatedTime: number;
  isLowPriority: boolean;
  generationType: GenerationType;
}

export interface BatchInferenceVideoResultDetails {
  generationId: string;
  savedVideo?: SavedVideoDTO | null;
  referenceId?: string | null;
  details?: string;
}

export interface BatchInferenceVideoResultResponse {
  successfulGenerations: BatchInferenceVideoResultDetails[];
  failedGenerations: BatchInferenceVideoResultDetails[];
  inProgressGenerations: BatchInferenceVideoResultDetails[];
  pendingGenerationStatus?: UserGenerationsData[];
}

const getDurationFromModel = (state: VideoGenerationState, model: VideoModels): string => {
  switch (model) {
    case VideoModels.I2V_01_LIVE_MINIMAX:
    case VideoModels.COG_X:
    case VideoModels.RAY_2:
      return VideoDuration.FIVE_SECONDS;
    case VideoModels.VEO2:
      return '8';
    default:
      return state.duration;
  }
};
export const getGenerationRequestFromVideoGenerationState = (
  state: VideoGenerationState,
  showId: string,
  episodeId: string,
  width: number,
  height: number
): GenerateImg2VideoDTO => {
  const config = state.videoGenConfig.find(config => config.modelName === state.model);
  const initImageTypesAllowed = config?.imageInputEnumsEnabled.map(item => item.type);
  return {
    showId,
    episodeId,
    prompt: state.prompt,
    duration: getDurationFromModel(state, state.model),
    initImages: state.initImages.filter(image => initImageTypesAllowed?.includes(image.type)),
    model: getModelId(state.model),
    aspectRatio: state.aspectRatio,
    metadata: state.metadata,
    batchCount: state.batchCount,
    width,
    height,
    negativePrompt: state.negativePrompt,
    numInferenceSteps: state.numInferenceSteps,
    guidanceScale: state.guidanceScale,
    exportFps: state.exportFps,
    loopEnabled: state.loopEnabled,
    numFrames: state.numFrames,
  };
};

const getImageFromSavedImage = (imageId: number, savedImages: SaveImageResponse[]) => {
  return savedImages.find(image => image.id === imageId);
};

export const getImg2VideoBulkGenerationRequests = ({
  state,
  showId,
  episodeId,
  savedImages,
}: {
  state: VideoGenerationState;
  showId: string;
  episodeId: string;
  savedImages: SaveImageResponse[];
}): GenerateImg2VideoDTO[] => {
  const requests = state.bulkGenerateMode.promptData
    .map((block, idx) => {
      const images = block.image
        .map(img => {
          const image = getImageFromSavedImage(img.id, savedImages);
          if (!image) return;
          return {
            id: image.id,
            url: image.imageURL,
            type: img.type,
          } as InitImage;
        })
        .filter((image): image is InitImage => image !== undefined);

      if (block.prompt.length === 1) {
        const image = getImageFromSavedImage(block.image[0].id, savedImages);
        if (image === undefined) return;
        return getGenerationRequestFromVideoGenerationState(
          {
            ...state,
            prompt: block.prompt[0],
            initImages: images,
          },
          showId,
          episodeId,
          image.imageWidth ?? 0,
          image.imageHeight ?? 0
        );
      }

      if (block.prompt.length > 1) {
        const image = getImageFromSavedImage(block.image[0].id, savedImages);
        if (!image) return;
        return block.prompt.map(prompt => {
          return getGenerationRequestFromVideoGenerationState(
            {
              ...state,
              prompt,
              initImages: images,
            },
            showId,
            episodeId,
            image.imageWidth ?? 0,
            image.imageHeight ?? 0
          );
        });
      }
    })
    .flat();

  return requests.filter((request): request is GenerateImg2VideoDTO => request !== undefined);
};

const generateLipSyncRequest = ({
  showId,
  episodeId,
  initLipSyncInputUrls,
  model,
  metadata,
  width,
  height,
  aspectRatio,
}: {
  showId: string;
  episodeId: string;
  initLipSyncInputUrls: InitLipSyncData[];
  model: VideoModelsIds;
  metadata: SavedVideoMetadata[];
  width: number;
  height: number;
  aspectRatio: string;
}): GenerateLipSyncVideoDTO => {
  return {
    showId,
    episodeId,
    initLipSyncInputUrls,
    model,
    metadata,
    width,
    height,
    aspectRatio,
  };
};

export const generateLipSyncBulkGenerationThunk =
  ({ showId, episodeId }: { showId: string; episodeId: string }) =>
  async (dispatch: AppDispatch, getState: () => ReduxState): Promise<VideoGenerationResponse[]> => {
    try {
      const requests: GenerateLipSyncVideoDTO[] = [];
      const state = getState();
      const inputData = state.videoGenerationState.lipSyncBulkGenerationData.inputData;
      inputData.forEach(item => {
        const genId = item.videoGenerationId;
        const video = state.savedVideoState.savedVideos.find(video => video.generationId === genId);
        item.audioUrls.forEach((data, index) => {
          requests.push(
            generateLipSyncRequest({
              showId: showId,
              episodeId: episodeId,
              initLipSyncInputUrls: [{ videoUrl: item.videoUrl, audioUrl: data }],
              model: getModelId(state.videoGenerationState.model as VideoModels),
              metadata: [
                {
                  name: SavedVideoMetadataTypes.THUMBNAIL_URL,
                  value: item.videoThumbnailUrl,
                },
              ],
              width: video?.width ?? 0,
              height: video?.height ?? 0,
              aspectRatio: video?.aspectRatio ?? '',
            })
          );
        });
      });
      let err = [];
      let responses: VideoGenerationResponse[] = [];
      for (const request of requests) {
        try {
          const response = await generateLipSyncVideo(request);
          responses.push(response);
        } catch (e) {
          err.push(e);
        }
      }
      if (err.length > 0) {
        console.error('Error while generating bulk lip sync video');
        throw err;
      }
      dispatch({
        type: UserQuotaSubscriptionActions.UPDATE_CREDITS_BALANCE,
        payload: responses[responses.length - 1].walletBalance,
      });
      const genIds = responses.map(response => response.generationIds).flat();
      dispatch(addPendingVideoGeneration(genIds));
      return responses;
    } catch (err) {
      console.error('Error while generating bulk lip sync video');
      throw err;
    }
  };

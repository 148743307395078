import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../../Redux/ReduxInterface';
import {
  AddShowCharacterRequest,
  Character,
  CharacterLibraryViewState,
  CharacterStatus,
  Organisation,
} from '../../../../../Models/Character';
import {
  addShowCharacters,
  deleteShowCharacters,
  fetchCharactersForShow,
  fetchTrainingCharactersByUserForShow,
  fetchTrainingCharactersForShow,
  fetchTrainingOptions,
  getCharactersByFilters,
} from '../../../../../Requests/Studio/Character';
import { CharacterActions } from '../../../../../Redux/Actions/Character';
import { trackEvent } from '../../../../../Utils/Analytics';
import { TrackingEvents } from '../../../../../Constants/TrackingEvents';
import { Box, FormControl, MenuItem, Select, Skeleton, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { CharactersVisible, ShowDetailsCharacterView } from '../ShowDetailsCharacters';
import { t } from 'i18next';
import './CharacterLibrary.css';
import { openNotificationWithIcon } from '../../../../../Utils/Notification';
import useNotification from 'antd/es/notification/useNotification';
import { DEFAULT_IMAGE_URL } from '../../../../../Constants/Constants';
import CharacterComponent from './CharacterComponent';
import CustomTextField from '../../../../../Components/CustomTextfield/CustomTextField';
import { TrainingOptionsInterface } from '../../../../../Components/NewTrainingWorkflow/NewTrainingWorkflowMetadata';
import { returnAgeString, returnEthnicityString } from '../../../../../Utils/CharacterViewUtils';
import { ShowDetailsCharacterActions } from '../../../../../Redux/Actions/ShowDetailsCharacter';
import useCharacters from '../../../../../Hooks/useCharacter';
import { CharacterHeaderLibraryView } from './CharacterSection/Components/CharacterHeaderLibraryView';

export interface CharacterLibraryView {
  showId: string;
  currentCharacterView: CharactersVisible;
  setCurrentCharacterView: (currentCharacterView: CharactersVisible) => void;
  setCurrentCharacter: (character: Character) => void;
  setCharacterView: (characterView: ShowDetailsCharacterView) => void;
  userCanEdit: boolean;
  isScreen?: boolean;
}

export enum CharacterDisplayViewStates {
  ALL_CHARACTERS,
  ADDED_TO_SHOW,
}

const CharacterLibraryView: React.FC<CharacterLibraryView> = ({
  showId,
  currentCharacterView,
  setCurrentCharacter,
  setCharacterView,
  userCanEdit,
  isScreen,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const characters = useCharacters();
  const [isLoading, setIsLoading] = useState(false);
  const [characterHovered, setCharacterHovered] = useState<string>('');
  const [filterCharacters, setFilterCharacters] = useState<Character[]>([]);
  const [isApiLoading, setIsApiLoading] = useState<boolean>(false);
  const [trainingOptions, setTrainingOptions] = useState<TrainingOptionsInterface>();

  const showCharacters = useSelector((state: ReduxState) => state.character.showCharacters);
  const trainingCharacters = useSelector((state: ReduxState) => state.character.trainingCharacters);

  const [filterText, setFilterText] = useState<string>('');
  const [gender, setGender] = useState<string>('All');
  const [age, setAge] = useState<string>('All');
  const [ethnicity, setEthnicity] = useState<string>('All');
  const [sortByAlpha, setSortByAlpha] = useState<boolean>(true);
  const characterLibraryViewState = useSelector(
    (state: ReduxState) => state.character.characterModalLibraryViewState
  );
  const [organisation, setOrganisation] = useState<Organisation>(Organisation.ALL);
  const [characterDisplayViewState, setCharacterDisplayViewState] =
    useState<CharacterDisplayViewStates>(CharacterDisplayViewStates.ALL_CHARACTERS);
  const isOldPayingUserOrDashtoonUser = useSelector(
    (state: ReduxState) =>
      state.userEnabledFeatures.isOldPayingUser || state.userEnabledFeatures.isDashtoonUser
  );
  const isShowCharacter = (characterId: string) => {
    return showCharacters.some(showCharacter => showCharacter.character_id === characterId);
  };

  const isTrainedCharacter = (characterId: string) => {
    return trainingCharacters.some(trainingCharacter => trainingCharacter.id === characterId);
  };

  const filterCharactersOnVisibility = (characterId: string) => {
    return !isTrainedCharacter(characterId);
  };

  const getShowCharacters = async (): Promise<Character[] | void> => {
    setIsApiLoading(true);
    try {
      const [showTrainingCharacters, userTrainingCharacters] = await Promise.all([
        fetchTrainingCharactersForShow(showId),
        fetchTrainingCharactersByUserForShow(showId),
      ]);
      return userTrainingCharacters.concat(
        showTrainingCharacters.filter(
          (showTrainingCharacter: Character) =>
            !userTrainingCharacters.find(
              (userTrainingCharacter: Character) =>
                userTrainingCharacter.id === showTrainingCharacter.id
            )
        )
      );
    } catch (error) {
      console.error(`Unable to fetch characters: ${error}`);
    } finally {
      setIsApiLoading(false);
    }
  };

  const getImageUrl = (character: Character) => {
    const thumbnailImage = characters.getThumbnailImageForCharacter(character);
    if (thumbnailImage) {
      return thumbnailImage;
    }
    if (
      character.imageUrl !== '' &&
      (character.state === CharacterStatus.TRAINED ||
        character.state === CharacterStatus.REVIEWED ||
        character.state === CharacterStatus.QC_CREATED ||
        character.state === CharacterStatus.PUBLISHED)
    ) {
      return characters.getThumbnailImageForCharacter(character) ?? character.imageUrl;
    } else if (character?.uploadedImage !== undefined && character?.uploadedImage !== '') {
      return character?.uploadedImage;
    } else return DEFAULT_IMAGE_URL;
  };

  useEffect(() => {
    fetchTrainingOptions().then(response => setTrainingOptions(response));

    getShowCharacters().then(characters => {
      dispatch({ type: CharacterActions.UPDATE_TRAINING_CHARACTERS, payload: characters });
    });
  }, []);

  useEffect(() => {
    if (characterLibraryViewState === CharacterLibraryViewState.MY_CHARACTERS) return;
    setIsLoading(true);
    getCharactersByFilters({
      visibility: 'GLOBAL',
      gender: gender === '' ? 'All' : gender,
      age: returnAgeString(age),
      ethnicity: returnEthnicityString(ethnicity),
      showId,
    })
      .then(response => {
        setIsLoading(false);
        setFilterCharacters(response);
      })
      .catch(err => {
        setIsLoading(false);
      });
  }, [gender, age, ethnicity, characterLibraryViewState]);

  return (
    <Box style={{ width: '100%', height: '100%' }}>
      <CharacterHeaderLibraryView
        characterLibraryViewState={characterLibraryViewState}
        organisation={organisation}
        setOrganisation={setOrganisation}
        characterDisplayViewState={characterDisplayViewState}
        setCharacterDisplayViewState={setCharacterDisplayViewState}
        filterText={filterText}
        setFilterText={setFilterText}
      />
      <Box
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          marginTop: '16px',
          marginLeft: '1rem',
          width: '100%',
          height: '55%',
          overflow: 'scroll',
          WebkitTouchCallout: 'none',
          gap: 4,
        }}
      >
        {isLoading && (
          <>
            <Skeleton
              width={'10rem'}
              height={'10rem'}
              style={{ margin: '0px 4px 8px 4px', borderRadius: '8px' }}
              variant={'rectangular'}
              animation={'wave'}
            />
            <Skeleton
              width={'10rem'}
              height={'10rem'}
              style={{ margin: '0px 4px 8px 4px', borderRadius: '8px' }}
              variant={'rectangular'}
              animation={'wave'}
            />
            <Skeleton
              width={'10rem'}
              height={'10rem'}
              style={{ margin: '0px 4px 8px 4px', borderRadius: '8px' }}
              variant={'rectangular'}
              animation={'wave'}
            />
            <Skeleton
              width={'10rem'}
              height={'10rem'}
              style={{ margin: '0px 4px 8px 4px', borderRadius: '8px' }}
              variant={'rectangular'}
              animation={'wave'}
            />
          </>
        )}
        {!isLoading &&
          characterLibraryViewState === CharacterLibraryViewState.MY_CHARACTERS &&
          isOldPayingUserOrDashtoonUser && (
            <>
              <Box
                style={{
                  backgroundColor: theme.palette.surface.tertiary,
                  width: '10rem',
                  height: '10rem',
                  margin: '0px 4px 8px 4px',
                  borderRadius: '8px',
                  border: `1px solid ${theme.palette.divider}`,
                  cursor: 'pointer',
                }}
                onClick={e => {
                  // TODO: component needs refactor.
                  trackEvent(
                    {
                      event: TrackingEvents.createCharacterButton,
                      properties: {
                        entryPoint: 'characterLibraryView',
                      },
                    },
                    'CREATOR'
                  );
                  e.stopPropagation();
                  dispatch({
                    type: ShowDetailsCharacterActions.LIBRARY,
                    payload: ShowDetailsCharacterView.CREATE_CHARACTER,
                  });
                }}
              >
                <Box
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <AddCircleIcon
                    fill={theme.palette.object.secondary}
                    style={{
                      width: '64px',
                      height: '64px',
                    }}
                  />
                </Box>
                <Typography
                  fontWeight={'600'}
                  fontSize={'12px'}
                  color={theme.palette.text.primary}
                  margin={'-30px 0px 12px 12px'}
                >
                  {t('Create New Character')}
                </Typography>
              </Box>
              {!isLoading &&
                trainingCharacters
                  .sort((firstCharacter, secondCharacter) =>
                    sortByAlpha === true
                      ? firstCharacter.name.localeCompare(secondCharacter.name)
                      : secondCharacter.name.localeCompare(firstCharacter.name)
                  )
                  .filter(
                    (character: Character) =>
                      character.state !== CharacterStatus.VALIDATION &&
                      character.state !== CharacterStatus.CREATED &&
                      character.state !== CharacterStatus.DELETED
                  )
                  .filter(
                    (character: Character) =>
                      character.name.toLowerCase().includes(filterText.toLowerCase()) ||
                      character.description.toLowerCase().includes(filterText.toLowerCase()) ||
                      (character.generationMetadata &&
                        character.generationMetadata
                          ?.toLowerCase()
                          .includes(filterText.toLowerCase()))
                  )
                  //
                  // added to show filter
                  .filter(character => {
                    return characterDisplayViewState === CharacterDisplayViewStates.ADDED_TO_SHOW
                      ? isShowCharacter(character.id)
                        ? character
                        : null
                      : character;
                  })
                  .map(character => (
                    <CharacterComponent
                      key={character.id}
                      isGlobal={false}
                      getShowCharacters={getShowCharacters}
                      showId={showId}
                      showName={
                        showCharacters.find(show => show.character_id === character.id)?.name
                      }
                      currentCharacterView={currentCharacterView}
                      setCharacterHovered={setCharacterHovered}
                      handleOnClick={() => {}}
                      setCurrentCharacter={setCurrentCharacter}
                      setCharacterView={() => {}}
                      setUpdateCharacterView={() => {}}
                      isShowCharacter={isShowCharacter}
                      isTrainedCharacter={isTrainedCharacter}
                      imageUrl={getImageUrl(character)}
                      character={character}
                      addCharacterToShow={characters.addCharacterToShow}
                      removeCharacterFromShow={characters.removeCharacterFromShow}
                      userCanEdit={userCanEdit}
                      isHovered={characterHovered === character.id}
                      showEdit={true}
                      showDelete={true}
                    />
                  ))}
            </>
          )}
        {characterLibraryViewState === CharacterLibraryViewState.MY_CHARACTERS
          ? null
          : !isLoading &&
            characters
              .getCharactersByOrganisation(organisation)
              .sort((firstCharacter, secondCharacter) =>
                sortByAlpha === true
                  ? firstCharacter.name.localeCompare(secondCharacter.name)
                  : secondCharacter.name.localeCompare(firstCharacter.name)
              )
              .filter(
                (character: Character) =>
                  character.name.toLowerCase().includes(filterText.toLowerCase()) ||
                  character.description.toLowerCase().includes(filterText.toLowerCase()) ||
                  (character.generationMetadata &&
                    character.generationMetadata?.toLowerCase().includes(filterText.toLowerCase()))
              )
              .filter(character => {
                return characterDisplayViewState === CharacterDisplayViewStates.ADDED_TO_SHOW
                  ? isShowCharacter(character.id)
                    ? character
                    : null
                  : character;
              })
              .map(character => (
                <CharacterComponent
                  key={character.id}
                  getShowCharacters={getShowCharacters}
                  showId={showId}
                  isGlobal={true}
                  showName={showCharacters.find(show => show.character_id === character.id)?.name}
                  currentCharacterView={currentCharacterView}
                  setCharacterHovered={setCharacterHovered}
                  handleOnClick={() => {
                    // add to show or remove only
                  }}
                  setCurrentCharacter={setCurrentCharacter}
                  setCharacterView={() => {}}
                  setUpdateCharacterView={() => {}}
                  isShowCharacter={isShowCharacter}
                  isTrainedCharacter={isTrainedCharacter}
                  imageUrl={getImageUrl(character)}
                  character={character}
                  addCharacterToShow={characters.addCharacterToShow}
                  removeCharacterFromShow={characters.removeCharacterFromShow}
                  userCanEdit={userCanEdit}
                  isHovered={characterHovered === character.id}
                  showEdit={false}
                  showDelete={false}
                />
              ))}
      </Box>
    </Box>
  );
};

export default CharacterLibraryView;

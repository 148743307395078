import { ThunkAction } from 'redux-thunk';
import { ReduxState } from '../ReduxInterface';
import { AnyAction } from 'redux';
import { UserQuotaResponse } from '../../Models/User';
import { fetchUserQuotas } from '../../Requests/Studio/User';
import { UserQuotaSubscriptionActions } from './UserQuotaSubscription';
import { ClothesLibraryResponse } from '../../Models/Clothes';
import { getAllClothes } from '../../Requests/Studio/Clothes';
import { getAllImageActions } from '../../Requests/Studio/ImageActions';
import { getAllImageAnalysis } from '../../Requests/Studio/ImageAnalysis';
import { ImageAnalysisResponse } from '../../Models/ImageAnalysis/ImageAnalysisAPI';

export enum ImageAnalysisActions {
  SET_IMAGE_ANALYSIS_HISTORY = 'SET_IMAGE_ANALYSIS_HISTORY',
  ADD_IMAGE_ANALYSIS_HISTORY = 'ADD_IMAGE_ANALYSIS_HISTORY',
}

export const setImageAnalysisHistory =
  (showId: string, episodeId: string): ThunkAction<void, ReduxState, unknown, AnyAction> =>
  async dispatch => {
    try {
      const response: ImageAnalysisResponse[] = await getAllImageAnalysis(showId, episodeId);
      dispatch({ type: ImageAnalysisActions.SET_IMAGE_ANALYSIS_HISTORY, payload: response });
    } catch (error) {
      dispatch({
        type: ImageAnalysisActions.SET_IMAGE_ANALYSIS_HISTORY,
        payload: [],
      });
    }
  };

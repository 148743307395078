import {
  InitialVideoGenerationState,
  InitImage,
  InitImageType,
  VideoAspectRatio,
  VideoDuration,
  VideoGenerationState,
  VideoModels,
} from '../../../Models/VideoGeneration/VideoGeneration';
import { VideoGenerationActions } from '../../Actions/VideoGeneration/VideoGeneration';
import { SavedVideoDTO, SavedVideoMetadata } from '../../../Models/VideoGeneration/SavedVideo';
import { UserGenerationsData } from '../../../Models/PollRequests';
import {
  LipSyncVideoInputSource,
  VideoGenMode,
} from '../../../Components/CanvasV3/VideoMode/RightBar/VideoGenerationMenu/VideoGenMode';

export interface VideoGenerationAction {
  type: VideoGenerationActions;
  payload?: any;
}

export const VideoGenerationReducer = (
  state: VideoGenerationState = InitialVideoGenerationState,
  action: VideoGenerationAction
): VideoGenerationState => {
  switch (action.type) {
    case VideoGenerationActions.SET_MODEL:
      return { ...state, model: action.payload as VideoModels };

    case VideoGenerationActions.SET_PROMPT:
      return { ...state, prompt: action.payload as string };

    case VideoGenerationActions.SET_NEGATIVE_PROMPT:
      return { ...state, negativePrompt: action.payload as string };

    case VideoGenerationActions.SET_DURATION:
      return { ...state, duration: action.payload as VideoDuration };

    case VideoGenerationActions.SET_ASPECT_RATIO:
      return { ...state, aspectRatio: action.payload as VideoAspectRatio };

    case VideoGenerationActions.SET_EXPORT_FPS:
      return { ...state, exportFps: action.payload as number };

    case VideoGenerationActions.SET_LOOP:
      return { ...state, loopEnabled: action.payload as boolean };

    case VideoGenerationActions.SET_GUIDANCE_SCALE:
      return { ...state, guidanceScale: action.payload as number };

    case VideoGenerationActions.SET_NUM_STEPS:
      return { ...state, numInferenceSteps: action.payload as number };

    case VideoGenerationActions.SET_NUM_FRAMES:
      return { ...state, numFrames: action.payload as number };

    case VideoGenerationActions.ADD_INIT_IMAGE:
      return { ...state, initImages: [...state.initImages, action.payload as InitImage] };

    case VideoGenerationActions.REMOVE_INIT_IMAGE:
      return {
        ...state,
        initImages: state.initImages.filter(image => image.type !== action.payload),
      };

    case VideoGenerationActions.SET_BATCH_COUNT:
      return { ...state, batchCount: action.payload as number };

    case VideoGenerationActions.ADD_METADATA:
      return { ...state, metadata: [...state.metadata, action.payload as SavedVideoMetadata] };

    case VideoGenerationActions.REMOVE_METADATA:
      return {
        ...state,
        metadata: state.metadata.filter(metadata => metadata.name !== action.payload), // Remove by `name`
      };

    case VideoGenerationActions.ADD_PENDING_VIDEO_GENERATION:
      return {
        ...state,
        videoGenerationsPollingData: {
          ...state.videoGenerationsPollingData,
          pendingGenerationIds: [
            ...state.videoGenerationsPollingData.pendingGenerationIds,
            ...(action.payload as string[]),
          ],
        },
      };

    case VideoGenerationActions.REMOVE_PENDING_VIDEO_GENERATION:
      return {
        ...state,
        videoGenerationsPollingData: {
          ...state.videoGenerationsPollingData,
          pendingGenerationIds: state.videoGenerationsPollingData.pendingGenerationIds.filter(
            id => !action.payload.includes(id)
          ),
        },
      };

    case VideoGenerationActions.SET_SELECTED_VIDEO:
      return { ...state, selectedVideo: action.payload };

    case VideoGenerationActions.SET_PENDING_VIDEO_GENERATION:
      return { ...state, selectedPendingGeneration: action.payload as string };

    case VideoGenerationActions.ADD_PENDING_GENERATION_STATE:
      return {
        ...state,
        videoGenerationsPollingData: {
          ...state.videoGenerationsPollingData,
          pendingGenerationStates: [
            ...state.videoGenerationsPollingData.pendingGenerationStates,
            action.payload as UserGenerationsData,
          ],
        },
      };

    case VideoGenerationActions.SET_PENDING_GENERATION_STATE:
      return {
        ...state,
        videoGenerationsPollingData: {
          ...state.videoGenerationsPollingData,
          pendingGenerationStates: action.payload as UserGenerationsData[],
        },
      };

    case VideoGenerationActions.RESET_STATE:
      return { ...InitialVideoGenerationState };

    case VideoGenerationActions.SET_BULK_VIDEO_GENERATE_MODE:
      return {
        ...state,
        bulkGenerateMode: {
          enabled: action.payload as boolean,
          promptData: state.bulkGenerateMode.promptData || [{ prompt: [], image: [] }],
        },
      };
    case VideoGenerationActions.ADD_BULK_VIDEO_GENERATE_BLOCK:
      return {
        ...state,
        bulkGenerateMode: {
          enabled: state.bulkGenerateMode.enabled || false,
          promptData: [
            ...state.bulkGenerateMode.promptData,
            {
              prompt: [''],
              image: [
                { imageUrl: '', id: 1, type: InitImageType.FIRST_FRAME },
                { imageUrl: '', id: 2, type: InitImageType.MIDDLE_FRAME },
                { imageUrl: '', id: 3, type: InitImageType.LAST_FRAME },
              ],
              negativePrompt: [''],
            },
          ],
        },
      };
    case VideoGenerationActions.REMOVE_BULK_VIDEO_GENERATE_BLOCK:
      return {
        ...state,
        bulkGenerateMode: {
          enabled: state.bulkGenerateMode.enabled || false,
          promptData: state.bulkGenerateMode.promptData.filter((_, idx) => idx !== action.payload),
        },
      };
    case VideoGenerationActions.UPDATE_BULK_GENERATE_MODE:
      return {
        ...state,
        bulkGenerateMode: {
          enabled: state.bulkGenerateMode.enabled || false,
          promptData: state.bulkGenerateMode.promptData.map((data, idx) =>
            idx === action.payload.index ? { ...action.payload.data } : data
          ),
        },
      };
    case VideoGenerationActions.SET_VIDEO_GEN_MODE:
      return { ...state, genMode: action.payload as VideoGenMode };
    case VideoGenerationActions.SET_LIPSYNC_INPUT_SOURCE:
      return { ...state, lipSyncInputSource: action.payload as LipSyncVideoInputSource };
    case VideoGenerationActions.ADD_BULK_LIPSYNC_VIDEO_BLOCK:
      return {
        ...state,
        lipSyncBulkGenerationData: {
          inputData: [
            ...state.lipSyncBulkGenerationData.inputData,
            {
              audioUrls: [''],
              videoUrl: '',
              videoThumbnailUrl: '',
              videoGenerationId: '',
            },
          ],
        },
      };
    case VideoGenerationActions.ADD_NEW_VIDEO_LIPSYNC_BLOCK:
      const lastBlock =
        state.lipSyncBulkGenerationData.inputData[
          state.lipSyncBulkGenerationData.inputData.length - 1
        ];
      const shouldReplace =
        lastBlock.videoUrl === '' &&
        lastBlock.videoThumbnailUrl === '' &&
        lastBlock.videoGenerationId === '';
      if (shouldReplace) {
        return {
          ...state,
          lipSyncBulkGenerationData: {
            inputData: state.lipSyncBulkGenerationData.inputData.map((data, idx) =>
              idx === state.lipSyncBulkGenerationData.inputData.length - 1
                ? {
                    ...data,
                    videoUrl: action.payload.videoUrl,
                    videoThumbnailUrl: action.payload.videoThumbnailUrl,
                    videoGenerationId: action.payload.generationId,
                  }
                : data
            ),
          },
        };
      }
      return {
        ...state,
        lipSyncBulkGenerationData: {
          inputData: [
            ...state.lipSyncBulkGenerationData.inputData,
            {
              audioUrls: [''],
              videoUrl: action.payload.videoUrl,
              videoThumbnailUrl: action.payload.videoThumbnailUrl,
              videoGenerationId: action.payload.generationId,
            },
          ],
        },
      };
    case VideoGenerationActions.REMOVE_BULK_LIPSYNC_VIDEO_BLOCK:
      return {
        ...state,
        lipSyncBulkGenerationData: {
          inputData: state.lipSyncBulkGenerationData.inputData.filter(
            (_, idx) => idx !== action.payload
          ),
        },
      };
    case VideoGenerationActions.ADD_NEW_AUDIO_CLIP:
      return {
        ...state,
        lipSyncBulkGenerationData: {
          inputData: state.lipSyncBulkGenerationData.inputData.map((data, idx) =>
            idx === action.payload
              ? {
                  ...data,
                  audioUrls: [...data.audioUrls, ''],
                }
              : data
          ),
        },
      };
    case VideoGenerationActions.SET_LIPSYNC_AUDIO_CLIP:
      return {
        ...state,
        lipSyncBulkGenerationData: {
          inputData: state.lipSyncBulkGenerationData.inputData.map((data, idx) =>
            idx === action.payload.blockIndex
              ? {
                  ...data,
                  audioUrls: data.audioUrls.map((url, audioIndex) =>
                    audioIndex === action.payload.audioIndex ? action.payload.audioUrl : url
                  ),
                }
              : data
          ),
        },
      };
    case VideoGenerationActions.REMOVE_AUDIO_CLIP:
      return {
        ...state,
        lipSyncBulkGenerationData: {
          inputData: state.lipSyncBulkGenerationData.inputData.map((data, idx) =>
            idx === action.payload.blockIndex
              ? {
                  ...data,
                  audioUrls: data.audioUrls.filter(
                    (_, audioIndex) => audioIndex !== action.payload.audioIndex
                  ),
                }
              : data
          ),
        },
      };
    case VideoGenerationActions.SET_LIPSYNC_VIDEO_CLIP:
      return {
        ...state,
        lipSyncBulkGenerationData: {
          inputData: state.lipSyncBulkGenerationData.inputData.map((data, idx) =>
            idx === action.payload.blockIndex
              ? {
                  ...data,
                  videoUrl: action.payload.videoUrl,
                  videoThumbnailUrl: action.payload.videoThumbnailUrl,
                  videoGenerationId: action.payload.generationId,
                }
              : data
          ),
        },
      };
    case VideoGenerationActions.SET_VIDEO_FAVOURITE_STATE:
      const { savedVideoId, isFavourite } = action.payload;
      const updatedSelectedVideo = (
        state.selectedVideo?.id === savedVideoId
          ? { ...state.selectedVideo, isFavourite }
          : state.selectedVideo
      ) as SavedVideoDTO | null;
      return {
        ...state,
        selectedVideo: updatedSelectedVideo,
      };

    default:
      return state;
  }
};

import { ShowMetaDataDTO } from '../../Models/Show';
import { AppDispatch } from '../../Hooks/useTypedDispatch';
import { updateShowMetadata } from '../../Requests/Studio/Show';
import { AppStateActions } from './AppState';

export enum ShowDetailsActions {
  SHOW_DETAILS = 'SHOW_DETAILS',
  SHOW_STYLE = 'SHOW_STYLE',
  SHOW_CHARACTERS = 'SHOW_CHARACTERS',
  SHOW_THUMBNAILS = 'SHOW_THUMBNAILS',
}

export interface ShowDetailsActionTypes {
  type: ShowDetailsActions;
  payload: any;
}

export const updateShowMetadataThunk =
  (showId: string, metadata: ShowMetaDataDTO) => async (dispatch: AppDispatch) => {
    try {
      const response = await updateShowMetadata(showId, [metadata]);
      dispatch({
        type: AppStateActions.UPDATE_SHOW_METADATA,
        payload: metadata,
      });
    } catch (err) {
      console.error('Error while updating show metadata', err);
    }
  };

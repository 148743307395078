import { StoryCrafterBlock } from './StoryCrafterBlocks';
import { LLMModels } from '../../Screens/Studio/ConfigScreen/ConfigScreen';

export interface EpisodeToScreenplayQueueRequest {
  showId: string;
  episodeId: string;
  episodeChunks: EpisodeChunkDTO[];
  gptModel?: LLMModels;
  systemPrompt?: string;
  isShortStory?: boolean;
}

export interface EpisodeChunkDTO {
  text: string;
  metadata: Record<string, string>;
}

export enum EpisodeChunkMetadataType {
  CHUNKING_TYPE = 'CHUNKING_TYPE',
  FORMAT_TYPE = 'FORMAT_TYPE',
}

export enum ChunkingType {
  MANUAL = 'MANUAL',
  AUTO = 'AUTO',
}

export enum ScreenplayFormatType {
  DEFAULT = 'DEFAULT',
  PROMO_VIDEO = 'PROMO_VIDEO',
  VIDEO_COMIC = 'VIDEO_COMIC',
}

export interface StoryCrafterEntity {
  entityId: string;
  type: StoryCrafterEntityType;
  details: StoryCrafterEntityDetails;
  episodeId: string;
  status?: StoryCrafterEntityStatus;
  createdAt?: Date;
  updatedAt?: Date;
}

export enum StoryCrafterEntityStatus {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
}

export enum StoryCrafterEntityType {
  DIALOG = 'DIALOG',
  PANEL = 'PANEL',
  BLOCK = 'BLOCK',
}

export interface StoryCrafterEntityDetails {}

export interface StoryCrafterPanelDetails extends StoryCrafterEntityDetails {
  description: string;
  location?: string;
  timeOfDay?: string;
  characters?: StoryCrafterPanelCharacter[];
  cameraAngle?: string;
  cameraShot?: string;
}

export interface StoryCrafterPanelCharacter {
  id: string;
  name: string;
  dress: string;
  pose: string;
  expression: string;
  dashCharacterId?: string | null;
  description?: string | null;
  actor?: string | null;
  dashCharName?: string | null;
}

export enum AudioPromptSetting {
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM',
}

export interface StoryCrafterDialogDetails extends StoryCrafterEntityDetails {
  dialogue: string;
  type: string;
  speaker: string;
  speakerId?: string;
  audioInstructions?: string;
  voiceId?: string;
  pendingUserGenerationIds?: string[];
  selectedSavedAudioId?: string;
  selectedSavedAudioUrl?: string;
  audioPromptSetting?: AudioPromptSetting;
}

export interface StoryCrafterBlockDetails extends StoryCrafterEntityDetails {
  panels: string[];
  dialogs: string[];
}

export interface StoryCrafterEntitiesRequest {
  entities: StoryCrafterEntity[];
  showId: string;
}

export interface DeleteEntitiesRequest {
  entityIds: string[];
  episodeId: string;
  showId: string;
}

export interface AddEntityRequest {
  episodeId: string;
  entityDTO: AddEntityDTO;
  previousId: string;
}

export interface CreateEntitiesRequest {
  episodeId: string;
  entities: AddEntityDTO[];
  insert: boolean;
  showId: string;
}

export interface AddEntitiesRequest {
  episodeId: string;
  entities: StoryCrafterEntity[];
  previousId: string | null;
}

export interface AddEntityDTO {
  details: StoryCrafterEntityDetails;
  type: StoryCrafterEntityType;
}

export interface StoryCrafterEntitiesResponse {
  entities: StoryCrafterEntity[];
}

export enum StreamResponseStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
}

export interface StoryCrafterStreamBlockResponse {
  result: string;
  errorMessage: string;
  status: StreamResponseStatus;
}

export interface StoryCrafterBlocksResponse {
  blocks: StoryCrafterBlock[];
}

export interface EntityAssociationsResponse {
  associations: StoryCrafterEntityAssociation[];
}

export interface StoryCrafterEntityAssociation {
  id: string;
  entityId: string;
  entityType: StoryCrafterEntityType;
  associationType: EntityAssociationType;
  associationValue: string;
  episodeId: string;
}

export interface StoryCrafterEntityAssociationDTO {
  entityId: string;
  entityType: StoryCrafterEntityType;
  associationType: EntityAssociationType;
  associationValue: string;
  episodeId: string;
}

export interface AddAssociationsRequest {
  associations: StoryCrafterEntityAssociationDTO[];
  showId: string;
}

export enum EntityAssociationType {
  SHAPE = 'SHAPE',
}

export interface FramePanelItem {
  entityId: string;
  frameId: string;
  image: string | null;
  generating: boolean;
}

export interface RegeneratePanelRequest {
  entity: StoryCrafterEntity;
  frameId: string;
  episodeId: string;
  showId: string;
  formatType: ScreenplayFormatType;
  blockId: string;
}

export interface EntityAndAssociation {
  entity: StoryCrafterEntity;
  associationValue: string;
}

export interface RegeneratePanelsRequest {
  panels: EntityAndAssociation[];
  episodeId: string;
  showId: string;
  formatType: ScreenplayFormatType;
  blockIds: string[];
}

export interface CharacterClothing {
  id: string;
  dress: string;
}

export enum DialogType {
  DIALOGUE = 'Dialogue',
  NARRATION = 'Narration',
  THOUGHT = 'Thought',
  SFX = 'SFX',
  OFF_PANEL = 'Off Panel',
  ON_PHONE = 'On Phone',
  TEXT_MESSAGE = 'Text Message',
  CHARACTER_INTRODUCTION = 'Character Introduction',
  FIRST_PERSON_NARRATION = 'First Person Narration',
  PRINT_TEXT = 'Print Text',
  SPEECH = 'Speech',
}

export const defaultDialogDetails: StoryCrafterDialogDetails = {
  dialogue: '',
  type: 'Dialogue' as DialogType,
  speaker: '',
};

export const defaultPanelDetails: StoryCrafterPanelDetails = {
  description: '',
  location: '',
  timeOfDay: '',
  cameraAngle: '',
  cameraShot: '',
  characters: [],
};

export const defaultBlockDetails: StoryCrafterBlockDetails = {
  panels: [],
  dialogs: [],
};

export interface DuplicateBlockRequest {
  previousBlock: StoryCrafterBlock;
  showId: string;
}

export interface AddBlocksRequest {
  blocks: StoryCrafterBlock[];
  prevBlockId: string | null;
  nextBlockId: string | null;
  showId: string;
}

export interface AddEntitiesToBlockRequest {
  blockId: string;
  entities: StoryCrafterEntity[];
  showId: string;
}
export enum StoryType {
  PROMPT2STORY = 'PROMPT2STORY',
  PRESET_STORY = 'PRESET_STORY',
  USER_STORY = 'USER_STORY',
}

export interface StoryCrafterGenerationsResponse {
  frameToGenerationIdMap: Record<string, string>;
  walletBalance: number;
}

export enum MigrateAction {
  MIGRATE = 'MIGRATE',
  DELETE = 'DELETE',
}

export interface MigrateCharacterInShowAndEpisodeToNewCharacterRequest {
  showId: string;
  episodeId: string;
  oldCharacterShowId: string;
  newCharacterShowId: string;
  applyToAllEpisodes: boolean;
  action: MigrateAction;
}

export enum EnhanceDialogType {
  SHORTEN = 'SHORTEN',
  FIX_GRAMMAR = 'FIX_GRAMMAR',
  FIX_SPELLING = 'FIX_SPELLING',
  CUSTOM_DIALOGUE_EDIT = 'CUSTOM_DIALOGUE_EDIT',
}

export interface DialogEnhanceRequest {
  text: string;
  prompt?: string;
  enhanceType: EnhanceDialogType;
}

export interface DialogEnhanceResponse {
  message: string;
}

export interface BlockImageDetails {
  imageUrl: string;
}

export interface BlockAudioDetails {
  audioUrl: string;
  audioGenerationId: string;
}

export interface BlockAssetDetails {
  blockId: string;
  imageDetails: BlockImageDetails[];
  audioDetails: BlockAudioDetails[];
}

export interface DownloadAssetsRequest {
  showId: string;
  episodeId: string;
  blockAssetDetails: BlockAssetDetails[];
}

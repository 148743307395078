import HttpClient from '../../Utils/HttpClient';
import { aiApiPrefix, apiPrefix, v2Prefix, videoPrefix } from '../../Constants/Constants';
import {
  BatchInferenceVideoResultResponse,
  GenerateImg2VideoDTO,
  GenerateLipSyncVideoDTO,
  VideoGenerationResponse,
} from '../../Models/VideoGeneration/VideoGeneration';
import { UploadVideoRequest } from '../../Models/VideoGeneration/SavedVideo';

export const generateImg2VideoRequest = async (
  request: GenerateImg2VideoDTO
): Promise<VideoGenerationResponse> => {
  try {
    return HttpClient.post(
      `${apiPrefix}${v2Prefix}${aiApiPrefix}${videoPrefix}/prompt-img2video`,
      request,
      {
        resourceId: request.showId,
      }
    );
  } catch (error) {
    console.error(`Unable to generate video`);
    throw error;
  }
};

export const fetchVideoGenerationResults = async (
  generationIds: string[]
): Promise<BatchInferenceVideoResultResponse> => {
  try {
    return HttpClient.post(
      `${apiPrefix}${v2Prefix}${aiApiPrefix}${videoPrefix}/generation-results`,
      {
        generationIds,
      }
    );
  } catch (error) {
    console.error(`Unable to fetch video generation results`);
    throw error;
  }
};

export const generateLipSyncVideo = async (
  request: GenerateLipSyncVideoDTO
): Promise<VideoGenerationResponse> => {
  try {
    return HttpClient.post(
      `${apiPrefix}${v2Prefix}${aiApiPrefix}${videoPrefix}/prompt-lipsync`,
      request,
      {
        resourceId: request.showId,
      }
    );
  } catch (error) {
    console.error(`Unable to generate video`);
    throw error;
  }
};

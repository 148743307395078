export interface VideoGenMode {
  id: string;
  name: string;
  description: string;
}

export enum VideoGenModeEnum {
  Image2Video = 'Image2Video',
  Video2Video = 'Video2Video',
  LipSync = 'LipSync',
}

export enum LipSyncVideoInputSource {
  Text = 'Text',
  Audio = 'Audio',
}

export const videoGenIds: Record<VideoGenModeEnum, string> = {
  [VideoGenModeEnum.Image2Video]: 'i2v',
  [VideoGenModeEnum.Video2Video]: 'v2v',
  [VideoGenModeEnum.LipSync]: 'lipSync',
};

export const VideoGenModes: VideoGenMode[] = [
  {
    id: videoGenIds[VideoGenModeEnum.Image2Video],
    name: VideoGenModeEnum.Image2Video,
    description: 'Create videos from images',
  },
  {
    id: videoGenIds[VideoGenModeEnum.LipSync],
    name: VideoGenModeEnum.LipSync,
    description: 'Create lip sync videos',
  },
];

export interface ClothesLibraryState {
  clothesLibrary: ClothesLibraryResponse[];
  selectedClothes: ClothesLibraryResponse | null;
  maskUrl: string | null;
  uploadedClothImage: string | null;
  createClothModalOpen: boolean;
}

export const InitialClothesLibraryState: ClothesLibraryState = {
  clothesLibrary: [],
  selectedClothes: null,
  maskUrl: null,
  uploadedClothImage: null,
  createClothModalOpen: false,
};

export interface ClothesLibraryInsertRequest {
  imageURL: string;
  showId: string;
  characterId: string;
  name: string;
}

export interface ClothesLibraryUpdateRequest {
  id: string;
  characterId: string;
  name: string;
}

export interface ClothesLibraryDeleteRequest {
  id: string;
}

export interface ClothesLibraryResponse {
  id: string;
  dressUrl: string;
  name: string;
  characterId: string;
  isEditable: boolean;
  updatedAt: string;
  searchString: string;
}

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useContext } from 'react';

import { ReduxState } from '../Redux/ReduxInterface';
import { trackEvent } from '../Utils/Analytics';
import { TrackingEvents } from '../Constants/TrackingEvents';
import { createNewShow } from '../Requests/Studio/Show';
import { config } from '../Config/Config';
import { ShowDTO, ShowMetaDataType, StudioShowAspectRatioSettingEnum } from '../Models/Show';
import { fetchCreateEpisodeData } from '../Requests/Studio/Episode';
import { EpisodeDTO } from '../Models/Episode';
import { AppStateActions } from '../Redux/Actions/AppState';
import { AuthContext } from '../Provider/AuthProvider';
import useUserMetadata from './useUserMetadata';
import { GenerationMenuActions } from '../Redux/Actions/GenerateImageV2';
import { CanvasMode } from '../Models/GenerateImageV2';
import {
  UserEnabledFeaturesResponse,
  UserEnabledFeaturesTypes,
} from '../Models/UserEnabledFeatures';
import { fetchUserEnabledFeatures } from '../Requests/Auth/UserEnabledFeatures';
import { getModeRoute } from '../Utils/CommonUtils';

const useCreateNewDashtoon = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const isDashtoonUser = useSelector(
    (state: ReduxState) =>
      state.userEnabledFeatures.isDashtoonUser || state.userEnabledFeatures.v3CanvasBetaTest
  );
  let generateRoute = `/generateV3/${getModeRoute(CanvasMode.STORY)}`;
  const { updateMetadata } = useUserMetadata();

  const handleCreateNewShow = async (
    source: string,
    route?: string,
    shouldRefresh?: boolean,
    isVideoComic?: boolean
  ) => {
    if (route) {
      generateRoute = route;
    }
    // checking api manually
    if (source === 'new-dashtoon') {
      const userEnabledFeatures: UserEnabledFeaturesResponse = await fetchUserEnabledFeatures();
      if (UserEnabledFeaturesTypes.V3_CANVAS_BETA_TEST in userEnabledFeatures) {
        generateRoute = `/generateV3${getModeRoute(CanvasMode.STORY)}`;
      }
    }
    if (currentUser === null) {
      dispatch({
        type: AppStateActions.OPEN_LOGIN_MODAL,
        payload: true,
      });
    } else if (currentUser.emailVerified) {
      setLoading(true);
      const showDetails = await createNewShow({
        name: currentUser.displayName
          ? `${currentUser.displayName}'s Untitled Dashtoon`
          : 'Untitled Dashtoon',
        creator: currentUser.displayName ? currentUser.displayName : currentUser.uid,
        thumbNailImageData: null,
        description: '',
        genre: '',
        trope: '',
        createdBy: currentUser.email,
        metadata: [
          {
            type: 'BANNER_THUMBNAIL_V2',
            value: '',
          },
          {
            type: 'WIDGET_THUMBNAIL_V2',
            value: '',
          },
          {
            type: 'DETAIL_PAGE_THUMBNAIL_V2',
            value: '',
          },
          {
            type: 'CONTINUE_READING_THUMBNAIL_V2',
            value: '',
          },
          {
            type: 'COMIC_STYLE',
            value: config.defaultStyle,
          },
          {
            type: 'STYLE_NAME',
            value: config.defaultStyleName,
          },
          {
            type: ShowMetaDataType.IS_VIDEO_COMIC,
            value: isVideoComic ? 'true' : 'false',
          },
          {
            type: ShowMetaDataType.STUDIO_ASPECT_RATIO_SETTING,
            value: isVideoComic
              ? StudioShowAspectRatioSettingEnum.ASPECT_RATIO_9_16
              : StudioShowAspectRatioSettingEnum.DEFAULT,
          },
        ],
        type: 'SERIALIZED',
      } as ShowDTO);
      if (showDetails) {
        trackEvent(
          {
            event: TrackingEvents.showCreated,
            properties: {
              showId: showDetails.id,
              showName: showDetails.name,
              creator: showDetails.creator,
              createdBy: showDetails.createdBy ? showDetails.createdBy : '',
              source: source,
            },
          },
          'CREATOR'
        );
      }
      const episodeDetails = await fetchCreateEpisodeData({
        name: 'Episode 1',
        releaseDate: Date.now(),
        showId: showDetails.id,
        sequence: 1,
        thumbNailImageData: null,
      } as EpisodeDTO);
      if (shouldRefresh) {
        window.location.href = [
          window.location.href.split('/episode/')[0],
          `${episodeDetails?.id}/generateV3/${getModeRoute(CanvasMode.STORY)}`,
        ].join('/episode/');
      } else {
        navigate(`/dashboard/show/${showDetails.id}/episode/${episodeDetails.id}${generateRoute}`, {
          state: { isNewEpisode: true },
        });
      }
      dispatch({
        type: GenerationMenuActions.SET_CANVAS_MODE,
        payload: {
          canvasMode: CanvasMode.STORY,
        },
      });
    }
  };

  const handleCreateNewShowUsingShowName = async (showName: string) => {
    if (currentUser === null) {
      return;
    }
    setLoading(true);
    const showDetails = await createNewShow({
      name: showName,
      creator: currentUser.displayName ? currentUser.displayName : currentUser.uid,
      thumbNailImageData: null,
      description: '',
      genre: '',
      trope: '',
      createdBy: currentUser.email,
      metadata: [
        {
          type: 'BANNER_THUMBNAIL_V2',
          value: '',
        },
        {
          type: 'WIDGET_THUMBNAIL_V2',
          value: '',
        },
        {
          type: 'DETAIL_PAGE_THUMBNAIL_V2',
          value: '',
        },
        {
          type: 'CONTINUE_READING_THUMBNAIL_V2',
          value: '',
        },
        {
          type: 'COMIC_STYLE',
          value: config.defaultStyle,
        },
        {
          type: 'STYLE_NAME',
          value: config.defaultStyleName,
        },
      ],
      type: 'SERIALIZED',
    } as ShowDTO);
    if (showDetails) {
      trackEvent(
        {
          event: TrackingEvents.showCreated,
          properties: {
            showId: showDetails.id,
            showName: showDetails.name,
            creator: showDetails.creator,
            createdBy: showDetails.createdBy ? showDetails.createdBy : '',
            source: 'BulkShowUpload',
          },
        },
        'CREATOR'
      );
    }
    return showDetails;
  };

  return { loading, handleCreateNewShow, handleCreateNewShowUsingShowName };
};

export default useCreateNewDashtoon;

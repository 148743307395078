export interface ImageAnalysisRequest {
  imageId: number;
  showId: string;
  episodeId: string;
  frameId: string;
  prompt: string;
}

export interface ImageAnalysisResponse {
  id: string;
  userId: string;
  userEmail: string;
  showId: string;
  episodeId: string;
  frameId: string;
  imageId: number;
  imageUrl: string;
  prompt: string;
  analysis: string;
  createdAt: string;
  updatedAt: string;
}

export interface ImageAnalysisReduxState {
  imageAnalysis: ImageAnalysisResponse[];
}

export const getInitialImageAnalysis = (): ImageAnalysisReduxState => {
  return {
    imageAnalysis: [],
  };
};

import {
  getSavedVideoByShowIdAndEpisodeId,
  markSavedVideoAsFavourite,
  uploadVideoToEpisode,
} from '../../../Requests/Studio/SavedVideo';
import { AppDispatch } from '../../../Hooks/useTypedDispatch';
import {
  MarkSavedVideoFavouriteRequest,
  UploadVideoRequest,
} from '../../../Models/VideoGeneration/SavedVideo';

export enum SavedVideoActions {
  ADD_SAVED_VIDEO = 'ADD_SAVED_VIDEO',
  REMOVE_SAVED_VIDEO = 'REMOVE_SAVED_VIDEO',
  ADD_MULTIPLE_SAVED_VIDEO = 'ADD_MULTIPLE_SAVED_VIDEO',
  RESET_SAVED_VIDEO = 'RESET_SAVED_VIDEO',
  SET_SAVED_VIDEO = 'SET_SAVED_VIDEO',
  SET_VIDEO_FAVOURITE_STATE = 'SET_VIDEO_FAVOURITE_STATE',
}

export const addMultipleSavedVideo = (payload: any) => ({
  type: SavedVideoActions.ADD_MULTIPLE_SAVED_VIDEO,
  payload,
});

export const setMultipleSavedVideo = (payload: any) => ({
  type: SavedVideoActions.SET_SAVED_VIDEO,
  payload,
});

export const setVideoFavouriteState = ({
  savedVideoId,
  isFavourite,
}: {
  savedVideoId: string;
  isFavourite: boolean;
}) => ({
  type: SavedVideoActions.SET_VIDEO_FAVOURITE_STATE,
  payload: {
    savedVideoId,
    isFavourite,
  },
});

export const fetchSavedVideoByShowIdAndEpisodeIdThunk =
  ({ showId, episodeId }: { showId: string; episodeId: string }) =>
  async (dispatch: AppDispatch) => {
    try {
      const response = await getSavedVideoByShowIdAndEpisodeId({ showId, episodeId });
      dispatch(addMultipleSavedVideo(response.reverse()));
    } catch (error) {
      console.error(`Unable to fetch saved video by show id and episode id: ${error}`);
      throw error;
    }
  };

export const uploadVideoToEpisodeThunk =
  (request: UploadVideoRequest) => async (dispatch: AppDispatch) => {
    try {
      const response = await uploadVideoToEpisode(request);
      dispatch(addMultipleSavedVideo([response]));
    } catch (error) {
      console.error(`Unable to upload video to episode: ${error}`);
      throw error;
    }
  };

export const markSavedVideoAsFavouriteThunk =
  (request: MarkSavedVideoFavouriteRequest) => async (dispatch: AppDispatch) => {
    try {
      const response = await markSavedVideoAsFavourite(request);
      dispatch(
        setVideoFavouriteState({
          savedVideoId: request.savedVideoId,
          isFavourite: request.isFavourite,
        })
      );
    } catch (error) {
      console.error(`Unable to mark saved video as favourite: ${error}`);
      throw error;
    }
  };

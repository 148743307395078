import { SaveImageType } from '../../../../../Models/SaveImage';
import { DashCharacterTypes } from '../../../../../Models/Character';
import {
  VideoGenerationState,
  VideoModels,
} from '../../../../../Models/VideoGeneration/VideoGeneration';
import { VideoGenModeEnum } from '../../../../../Components/CanvasV3/VideoMode/RightBar/VideoGenerationMenu/VideoGenMode';

export const calculateCreditsRequiredForCharacters = (type: DashCharacterTypes) => {
  switch (type) {
    case DashCharacterTypes.PROMPT: {
      return creditsUsage.characterCreationPrompt;
    }
    case DashCharacterTypes.TRAINED: {
      return creditsUsage.characterCreation;
    }
    default:
      throw new Error('Unknown SaveImageType');
  }
};
export const calculateCreditsRequired = (type: SaveImageType) => {
  switch (type) {
    case SaveImageType.DALLE_RESULT:
      return creditsUsage.compositionalModelInference;
    case SaveImageType.BACKGROUND_REMOVE_RESULT:
      return creditsUsage.removeBackground;

    case SaveImageType.CREATIVE_UPSCALE_RESULT:
      return creditsUsage.creativeUpscaler;

    case SaveImageType.UPSCALE_RESULT:
      return creditsUsage.simpleUpscaler;

    case SaveImageType.LIVE_RESULT:
      return creditsUsage.live;

    case SaveImageType.TXT2IMG_RESULT:
    case SaveImageType.IMG2IMG_RESULT:
    case SaveImageType.INPAINT_RESULT:
      return creditsUsage.styleInferenceWithCharacterS2C;

    default:
      console.info(`${type} type credits was requested. Returning 0`);
      return 0;
  }
};
const creditsUsage = {
  compositionalModelInference: 1,
  styleInferenceWithCharacterS2C: 1,
  creativeUpscaler: 3,
  simpleUpscaler: 3,
  removeBackground: 0,
  segment: 0,
  live: 1,
  magicEraser: 0,
  characterCreation: 50,
  characterCreationPrompt: 0,
  initialAccountCreation: 150,
  episodePublishRefund: 150,

  videoCreditsPerSecond: {
    [VideoModels.I2V_01_LIVE_MINIMAX]: 5,
    [VideoModels.Kling_1_Pro]: 7,
    [VideoModels.Kling_1_5_Pro]: 7,
    [VideoModels.Kling_1_6_Pro]: 7,
    [VideoModels.COG_X]: 5,
    [VideoModels.VEO2]: 7,
    [VideoModels.SYNC_LIPSYNC]: 5,
    [VideoModels.LATENT_SYNC]: 5,
    [VideoModels.RAY_2]: 7,
    [VideoModels.WAN_I2V]: 5,
    [VideoModels.WAN_PRO]: 9,
  } as Record<VideoModels, number>,
};

export default creditsUsage;

export const getCreditsNeededForVideo = (state: VideoGenerationState): number => {
  const creditsPerSecond = creditsUsage.videoCreditsPerSecond[state.model] ?? 0;

  const totalCredits =
    state.model === VideoModels.I2V_01_LIVE_MINIMAX
      ? 5 * creditsPerSecond
      : state.model === VideoModels.VEO2
      ? 8 * creditsPerSecond
      : parseInt(state.duration) * creditsPerSecond;

  return totalCredits;
};

export const getCreditsNeededForBulkVideo = (state: VideoGenerationState): number => {
  switch (state.genMode.name) {
    case VideoGenModeEnum.Image2Video: {
      const creditsPerSecond = creditsUsage.videoCreditsPerSecond[state.model] ?? 0;
      const bulkModeState = state.bulkGenerateMode;
      const totalCredits = bulkModeState.promptData.reduce((acc, data) => {
        if (data.image.length === 1 && data.prompt.length === 1) {
          return acc + creditsPerSecond * parseInt(state.duration);
        }
        const noOfPrompts = data.prompt.length;
        return acc + creditsPerSecond * noOfPrompts * parseInt(state.duration);
      }, 0);

      return totalCredits;
    }
    case VideoGenModeEnum.LipSync: {
      const creditsPerSecond = creditsUsage.videoCreditsPerSecond[state.model] ?? 0;
      const bulkModeState = state.lipSyncBulkGenerationData.inputData;
      const totalCredits = bulkModeState.reduce((acc, data) => {
        const total = data.audioUrls.length;
        return acc + creditsPerSecond * total * parseInt(state.duration);
      }, 0);
      return totalCredits;
    }
    default:
      return 0;
  }
};

export enum TextEnhancerType {
  DIALOG = 'DIALOG',
  THOUGHT = 'THOUGHT',
  NARRATION = 'NARRATION',
  SOUND_EFFECT = 'SOUND_EFFECT',
}

export interface TextEnhancerRequest {
  text: string;
  prompt: string;
  type: TextEnhancerType;
}

export interface TextEnhancerResponse {
  message: string;
}

export enum PromptEnhanceType {
  IMAGE_PROMPT = 'IMAGE_PROMPT',
  VIDEO_PROMPT = 'VIDEO_PROMPT',
}

export interface PromptEnhanceRequest {
  text: string;
  type: PromptEnhanceType;
}

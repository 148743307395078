import {
  getModelId,
  InitImageType,
  VideoAspectRatio,
  VideoDuration,
  VideoModels,
  VideoModelsIds,
} from './VideoGeneration';
import {
  VideoGenModeEnum,
  LipSyncVideoInputSource,
} from '../../Components/CanvasV3/VideoMode/RightBar/VideoGenerationMenu/VideoGenMode';

export interface IVideoGenConfig {
  modelName: VideoModels;
  modelId: VideoModelsIds;
  genModeEnabled: VideoGenModeEnum[];
  configEnabled: boolean;
  durationEnabled: boolean;
  durationEnumsEnabled: {
    type: VideoDuration;
    enabled: boolean;
  }[];
  negativePromptEnabled: boolean;
  imageInputsEnabled: boolean;
  imageInputEnumsEnabled: {
    type: InitImageType;
    enabled: boolean;
  }[];
  aspectRatioEnabled: boolean;
  aspectRatioEnumsEnabled: {
    type: VideoAspectRatio;
    enabled: boolean;
  }[];
  exportFpsEnabled: boolean;
  numOfInferenceStepsEnabled: boolean;
  cfgEnabled: boolean;
  lipSyncInputSourceEnabled: boolean;
  lipSyncInputSourceEnumsEnabled: {
    type: LipSyncVideoInputSource;
    enabled: boolean;
  }[];
  loopEnabled?: boolean;
  numOfFramesEnabled?: boolean;
  minFPS?: number;
  maxFPS?: number;
  maxNumOfInferenceSteps?: number;
}

const createConfig = (config: {
  model: VideoModels;
  genModeEnabled: VideoGenModeEnum[];
  configEnabled?: boolean;
  durationEnabled?: boolean;
  durationEnums?: { type: VideoDuration; enabled: boolean }[];
  negativePromptEnabled?: boolean;
  imageInputsEnabled?: boolean;
  imageInputEnums?: { type: InitImageType; enabled: boolean }[];
  aspectRatioEnabled?: boolean;
  aspectRatioEnums?: { type: VideoAspectRatio; enabled: boolean }[];
  exportFpsEnabled?: boolean;
  numOfInferenceStepsEnabled?: boolean;
  cfgEnabled?: boolean;
  lipSyncInputSourceEnabled?: boolean;
  lipSyncInputSourceEnums?: { type: LipSyncVideoInputSource; enabled: boolean }[];
  loopEnabled?: boolean;
  numOfFramesEnabled?: boolean;
  minFPS?: number;
  maxFPS?: number;
  maxNumOfInferenceSteps?: number;
}): IVideoGenConfig => {
  return {
    modelName: config.model,
    modelId: getModelId(config.model),
    genModeEnabled: config.genModeEnabled,
    configEnabled: config.configEnabled ?? false,
    durationEnabled: config.durationEnabled ?? false,
    durationEnumsEnabled: config.durationEnums ?? [
      { type: VideoDuration.FIVE_SECONDS, enabled: true },
      { type: VideoDuration.TEN_SECONDS, enabled: true },
    ],
    negativePromptEnabled: config.negativePromptEnabled ?? false,
    imageInputsEnabled: config.imageInputsEnabled ?? false,
    imageInputEnumsEnabled: config.imageInputEnums ?? [
      { type: InitImageType.FIRST_FRAME, enabled: true },
    ],
    aspectRatioEnabled: config.aspectRatioEnabled ?? false,
    aspectRatioEnumsEnabled: config.aspectRatioEnums ?? [
      { type: VideoAspectRatio.NINE_BY_SIXTEEN, enabled: true },
      { type: VideoAspectRatio.SIXTEEN_BY_NINE, enabled: true },
    ],
    exportFpsEnabled: config.exportFpsEnabled ?? false,
    numOfInferenceStepsEnabled: config.numOfInferenceStepsEnabled ?? false,
    cfgEnabled: config.cfgEnabled ?? false,
    lipSyncInputSourceEnabled: config.lipSyncInputSourceEnabled ?? false,
    lipSyncInputSourceEnumsEnabled: config.lipSyncInputSourceEnums ?? [],
    loopEnabled: config.loopEnabled ?? false,
    numOfFramesEnabled: config.numOfFramesEnabled ?? false,
    minFPS: config.minFPS ?? 0,
    maxFPS: config.maxFPS ?? 0,
    maxNumOfInferenceSteps: config.maxNumOfInferenceSteps ?? 0,
  };
};
export const getInitVideoConfigState = (): IVideoGenConfig[] => {
  return [
    createConfig({
      model: VideoModels.Kling_1_Pro,
      configEnabled: true,
      durationEnabled: true,
      negativePromptEnabled: false,
      imageInputsEnabled: true,
      imageInputEnums: [
        { type: InitImageType.FIRST_FRAME, enabled: true },
        { type: InitImageType.LAST_FRAME, enabled: true },
      ],
      genModeEnabled: [VideoGenModeEnum.Image2Video],
    }),
    createConfig({
      model: VideoModels.Kling_1_5_Pro,
      configEnabled: true,
      durationEnabled: true,
      negativePromptEnabled: false,
      imageInputsEnabled: true,
      genModeEnabled: [VideoGenModeEnum.Image2Video],
    }),
    createConfig({
      model: VideoModels.Kling_1_6_Pro,
      configEnabled: true,
      durationEnabled: true,
      negativePromptEnabled: false,
      imageInputsEnabled: true,
      genModeEnabled: [VideoGenModeEnum.Image2Video],
    }),
    createConfig({
      model: VideoModels.I2V_01_LIVE_MINIMAX,
      configEnabled: true,
      durationEnabled: false,
      negativePromptEnabled: false,
      imageInputsEnabled: true,
      genModeEnabled: [VideoGenModeEnum.Image2Video],
    }),
    createConfig({
      model: VideoModels.COG_X,
      configEnabled: true,
      durationEnabled: false,
      negativePromptEnabled: true,
      imageInputsEnabled: true,
      exportFpsEnabled: true,
      numOfInferenceStepsEnabled: true,
      cfgEnabled: true,
      genModeEnabled: [VideoGenModeEnum.Image2Video],
      minFPS: 4,
      maxFPS: 32,
      maxNumOfInferenceSteps: 50,
    }),
    createConfig({
      model: VideoModels.VEO2,
      configEnabled: true,
      durationEnabled: false,
      negativePromptEnabled: true,
      imageInputsEnabled: true,
      aspectRatioEnabled: true,
      genModeEnabled: [VideoGenModeEnum.Image2Video],
    }),
    createConfig({
      model: VideoModels.RAY_2,
      configEnabled: true,
      durationEnabled: false,
      negativePromptEnabled: false,
      imageInputsEnabled: true,
      imageInputEnums: [
        { type: InitImageType.FIRST_FRAME, enabled: true },
        { type: InitImageType.LAST_FRAME, enabled: true },
      ],
      genModeEnabled: [VideoGenModeEnum.Image2Video],
      aspectRatioEnabled: true,
      loopEnabled: true,
    }),
    createConfig({
      model: VideoModels.LATENT_SYNC,
      configEnabled: true,
      durationEnabled: false,
      negativePromptEnabled: false,
      imageInputsEnabled: false,
      aspectRatioEnabled: false,
      genModeEnabled: [VideoGenModeEnum.LipSync],
      lipSyncInputSourceEnabled: true,
      lipSyncInputSourceEnums: [
        { type: LipSyncVideoInputSource.Text, enabled: false },
        { type: LipSyncVideoInputSource.Audio, enabled: true },
      ],
    }),
    createConfig({
      model: VideoModels.SYNC_LIPSYNC,
      configEnabled: true,
      durationEnabled: false,
      negativePromptEnabled: false,
      imageInputsEnabled: false,
      aspectRatioEnabled: false,
      genModeEnabled: [VideoGenModeEnum.LipSync],
      lipSyncInputSourceEnabled: true,
      lipSyncInputSourceEnums: [
        { type: LipSyncVideoInputSource.Text, enabled: false },
        { type: LipSyncVideoInputSource.Audio, enabled: true },
      ],
    }),
    createConfig({
      model: VideoModels.WAN_I2V,
      configEnabled: true,
      durationEnabled: false,
      negativePromptEnabled: true,
      imageInputsEnabled: true,
      exportFpsEnabled: true,
      numOfInferenceStepsEnabled: true,
      numOfFramesEnabled: true,
      cfgEnabled: false,
      genModeEnabled: [VideoGenModeEnum.Image2Video],
      minFPS: 5,
      maxFPS: 24,
      maxNumOfInferenceSteps: 40,
    }),
    createConfig({
      model: VideoModels.WAN_PRO,
      configEnabled: true,
      durationEnabled: false,
      negativePromptEnabled: false,
      imageInputsEnabled: true,
      exportFpsEnabled: false,
      numOfInferenceStepsEnabled: false,
      numOfFramesEnabled: false,
      cfgEnabled: false,
      genModeEnabled: [VideoGenModeEnum.Image2Video],
    }),
  ];
};

import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { CharacterActions } from '../../../../../../../Redux/Actions/Character';
import { CharacterLibraryViewState, Organisation } from '../../../../../../../Models/Character';
import CustomTextField from '../../../../../../../Components/CustomTextfield/CustomTextField';
import { t } from 'i18next';
import React from 'react';
import { CharacterDisplayViewStates } from '../../CharacterLibraryView';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../../../../Redux/ReduxInterface';

interface CharacterHeaderLibraryViewProps {
  characterLibraryViewState: CharacterLibraryViewState;
  characterDisplayViewState: CharacterDisplayViewStates;
  setCharacterDisplayViewState: React.Dispatch<React.SetStateAction<CharacterDisplayViewStates>>;
  filterText: string;
  setFilterText: React.Dispatch<React.SetStateAction<string>>;
  organisation: Organisation;
  setOrganisation: React.Dispatch<React.SetStateAction<Organisation>>;
}
export const CharacterHeaderLibraryView: React.FC<CharacterHeaderLibraryViewProps> = ({
  characterLibraryViewState,
  characterDisplayViewState,
  setCharacterDisplayViewState,
  filterText,
  setFilterText,
  organisation,
  setOrganisation,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDashtoonUser = useSelector(
    (state: ReduxState) => state.userEnabledFeatures.isDashtoonUser
  );

  return (
    <Box style={{ marginLeft: '1rem', marginRight: '1rem' }}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          padding: '0px',
          alignItems: 'baseline',
          height: '24px',
        }}
      >
        <Box
          style={{ cursor: 'pointer' }}
          onClick={() =>
            dispatch({
              type: CharacterActions.SET_CHARACTER_MODAL_LIBRARY_VIEW_STATE,
              payload: CharacterLibraryViewState.CHARACTER_LIBRARY,
            })
          }
        >
          <Typography
            style={{
              fontSize: '0.75rem',
              fontWeight: '600',
              color:
                characterLibraryViewState === CharacterLibraryViewState.CHARACTER_LIBRARY
                  ? theme.palette.primary.main
                  : theme.palette.action.active,
            }}
          >
            Character Library
          </Typography>
        </Box>
        <Box
          style={{ marginLeft: '1rem', cursor: 'pointer' }}
          onClick={() => {
            dispatch({
              type: CharacterActions.SET_CHARACTER_MODAL_LIBRARY_VIEW_STATE,
              payload: CharacterLibraryViewState.MY_CHARACTERS,
            });
          }}
        >
          <Typography
            style={{
              fontSize: '0.75rem',
              fontWeight: '600',
              color:
                characterLibraryViewState === CharacterLibraryViewState.MY_CHARACTERS
                  ? theme.palette.primary.main
                  : theme.palette.action.active,
            }}
          >
            My Characters
          </Typography>
        </Box>
      </Box>
      <Box style={{ display: 'flex', marginTop: '1.5rem', position: 'relative' }}>
        <Box style={{ display: 'flex', width: '70%', height: '100%' }}>
          {!isDashtoonUser ? (
            <Box
              onClick={() =>
                setCharacterDisplayViewState(CharacterDisplayViewStates.ALL_CHARACTERS)
              }
              style={{
                cursor: 'pointer',

                height: '2rem',
                width: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color:
                  characterDisplayViewState === CharacterDisplayViewStates.ALL_CHARACTERS
                    ? theme.palette.object.primary
                    : theme.palette.action.active,
                backgroundColor:
                  characterDisplayViewState === CharacterDisplayViewStates.ALL_CHARACTERS
                    ? theme.palette.action.focus
                    : 'none',
                padding: '0.5rem 0.935rem',
                borderRadius: '1.65rem',
                border: `1px solid ${theme.palette.surface.tertiary}`,
              }}
            >
              <Typography style={{ fontSize: '0.75rem', fontWeight: '600' }}>
                All Characters
              </Typography>
            </Box>
          ) : (
            <FormControl style={{ minWidth: 120 }}>
              <Select
                value={organisation}
                onChange={e => setOrganisation(e.target.value as Organisation)}
                onClick={() =>
                  setCharacterDisplayViewState(CharacterDisplayViewStates.ALL_CHARACTERS)
                }
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{
                  cursor: 'pointer',

                  height: '2rem',
                  width: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color:
                    characterDisplayViewState === CharacterDisplayViewStates.ALL_CHARACTERS
                      ? theme.palette.object.primary
                      : theme.palette.action.active,
                  backgroundColor:
                    characterDisplayViewState === CharacterDisplayViewStates.ALL_CHARACTERS
                      ? theme.palette.action.focus
                      : 'none',
                  padding: '0.5rem 0.935rem',
                  borderRadius: '1.65rem',
                  border: `1px solid ${theme.palette.surface.tertiary}`,
                }}
              >
                <MenuItem value={Organisation.ALL}>All Characters</MenuItem>
                <MenuItem value={Organisation.DASHTOON}>Dashtoon</MenuItem>
              </Select>
            </FormControl>
          )}
          <Box
            onClick={() => setCharacterDisplayViewState(CharacterDisplayViewStates.ADDED_TO_SHOW)}
            style={{
              cursor: 'pointer',
              height: '2rem',
              width: 'auto',
              marginLeft: '1rem',
              color:
                characterDisplayViewState === CharacterDisplayViewStates.ADDED_TO_SHOW
                  ? theme.palette.object.primary
                  : theme.palette.action.active,
              backgroundColor:
                characterDisplayViewState === CharacterDisplayViewStates.ADDED_TO_SHOW
                  ? theme.palette.action.focus
                  : 'none',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0.5rem 0.935rem',
              borderRadius: '1.65rem',
              border: `1px solid ${theme.palette.surface.tertiary}`,
            }}
          >
            <Typography style={{ fontSize: '0.75rem', fontWeight: '600' }}>
              Added to Dashtoon
            </Typography>
          </Box>
        </Box>
        <Box
          style={{
            position: 'absolute',
            top: '-20px',
            right: '0px',
            flex: '1',
            fontSize: '0.75rem',
            padding: '0.5rem 0.935rem',
            borderRadius: '1rem',
          }}
        >
          <CustomTextField
            style={{ borderRadius: '3rem' }}
            width={'100%'}
            height={'100%'}
            noOfLines={1}
            label={''}
            placeholder={t('Search')}
            isV2={true}
            inputProps={{ value: filterText }}
            onChange={event => setFilterText(event.target.value)}
          />
        </Box>
      </Box>
    </Box>
  );
};
